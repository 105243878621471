import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row } from "reactstrap";
import './AiTestTracker.css';
import CommentsDrawer from  './CommentsDrawer';
import { useGetAiTestMembers, useAddAiTestReport } from "./useAiTestHooks";
import AiReportFilters from "./AiReportFilters";

const AiTestTracker = (props) => {
    const [opencount, setOpenCount] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [myId, setmyId] = useState(0);
    const [month, setMonth] = useState(0);
    const [userType, setUserType] = useState(1);
    const [viewMember, setViewMember] = useState(0);
    const history = useHistory();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [myPrivileges, setMyPrivileges] = useState([]);

    useEffect(() => {
        checkUserPrivilege();
    }, []);
    
    const checkUserPrivilege = () => {
        if (localStorage.getItem("user") !== null) {
            var login = JSON.parse(localStorage.getItem("user"));
            setmyId(login.uid);
            setMyPrivileges(login.privileges);
            if (login.privileges.includes("access_all_reports")) {
                setUserType(1);
            } else if (login.privileges.includes("access_team_reports")) {
                setUserType(2);
            } else {
                setUserType(3);
            }
        }
    };
    
    const { isLoading: dataLoading, data: initialData } = useGetAiTestMembers(
        month,
        viewMember
    );

    function toggleSidebar() {
        setshowMenu(!showMenu);
    }
    
    function hideMenu() {
        setshowMenu(!showMenu);
    }

    const [testData, setTestData] = useState([]);

    useEffect(() => {
        setTestData(initialData?.data.users);
    }, [initialData]);

    const getStatusClass = (status) => {
        switch(parseInt(status)) {
            case 1:
                return 'text-success';
            case 2:
                return 'text-danger';
            case 3:
                return 'text-warning';
            default:
                return '';
        }
    };

    const getStatusLabel = (status) => {
        switch(parseInt(status)) {
            case 1:
                return 'Pass';
            case 2:
                return 'Failed';
            case 3:
                return 'Average';
            default:
                return '';
        }
    };

    const handleLinkClick = (id) => {
        if(testData[id].test_link!== null)
        { 
            window.open(testData[id].test_link, '_blank');
        }
    };

    const handleViewMore = (index) => {
        setCurrentIndex(index);
        setIsDrawerOpen(true);
    };

    const handleNext = () => {
        if (currentIndex < testData?.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleUpdate = (index, updatedItem) => {
        const newData = [...testData];
        newData[index] = updatedItem;
        console.log(updatedItem);
        setTestData(newData);
        addAiReport(updatedItem);
    };

   

  const { mutate: addAiReport, isLoading: addLoading } = useAddAiTestReport();
    

    useEffect(() => {
        if (isDrawerOpen) {
            document.body.classList.add('drawer-open');
        } else {
            document.body.classList.remove('drawer-open');
        }
        
        return () => {
            document.body.classList.remove('drawer-open');
        };
    }, [isDrawerOpen]);

    const abbreviateName = (name) => {
        const nameParts = name.split(' ');
        const firstName = nameParts[0];
        const abbreviatedMiddleAndLast = nameParts
        .slice(1)
        .map(part => part[0])
        .join(' ');
        return `${firstName} ${abbreviatedMiddleAndLast}`.trim();
    };

    const updateMonth = (value) => {
        setMonth(value);
      };
    
      const updateSelectedMember = (value) => {
        setViewMember(value);
      };

      const  getFirstName = (fullName)=>{
        const parts = fullName.split(/[-\s]+/);
        return parts[0];
      }
    return (
        <div className="fullwidth_mobilemenu mobileflex">
            <Container fluid className="">
                <Row>
                    <div className="theme_header">
                        <Header
                            ncount={opencount}
                            {...props}
                            className="tick-timer-header"
                            quickactionblock={false}
                        />
                    </div>
                    <div className="SideNavbar">
                        <SideMenu
                            activeitem="report"
                            showmenu={showMenu}
                            hidemenu={hideMenu}
                        />
                    </div>
                    <div className="themeContentBody aitrackerpage">
                        <AiReportFilters
                         month={month}
                         userType={userType}
                         onMonthChange={updateMonth}
                         updateSelectedMember={updateSelectedMember}
                        />
                        <div className="mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Team</th>
                                                    <th>PL</th>
                                                    <th>COM</th>
                                                    <th>Test Date</th>
                                                    <th>Test Link</th>
                                                    <th>Time Taken</th>
                                                    <th>Result</th>
                                                    <th>View More</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {testData?.length >0  && (
                                                    <>
                                                        {testData.map((item, index) => (
                                                                <tr 
                                                                    key={item.user_id} 
                                                                    className={isDrawerOpen && currentIndex === index ? 'table-active' : ''}
                                                                >
                                                                    <td>{abbreviateName(item.name)}</td>
                                                                    <td>{item.team}</td>
                                                                    <td>{item.pl!='' && item.pl !=null && getFirstName(item.pl)}</td>
                                                                    <td>{item.com !='' && item.com !=null && getFirstName(item.com)}</td>
                                                                    <td>{item.test_date}</td>
                                                                    <td>
                                                                        {item.test_link!== null ? 
                                                                        (
                                                                            <a
                                                                                href="#"
                                                                                className="text-primary" 
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    handleLinkClick(index);
                                                                                }}
                                                                            >
                                                                                Link
                                                                            </a>
                                                                        ) : '-'}   
                                                                        
                                                                    </td>
                                                                    <td>{item.timeTakenHours >0 && `${item.timeTakenHours>1 ? item.timeTakenHours+" hrs" : item.timeTakenHours+" hr"}`} 
                                                                        {item.timeTakenHours >0 && item.timeTakenMinutes >0 && ' & '}
                                                                        {item.timeTakenMinutes >0 && `${item.timeTakenMinutes} mins`}</td>
                                                                    <td className={`${item.status >0 && getStatusClass(item.status)}`}>
                                                                        {item.status>0 ? getStatusLabel(item.status) : '-'}
                                                                    </td>
                                                                    <td className="view-more-test-data" onClick={() => handleViewMore(index)}>View</td>
                                                                </tr>
                                                            ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isDrawerOpen && (
                            <CommentsDrawer 
                                isOpen={isDrawerOpen}
                                onClose={() => setIsDrawerOpen(false)}
                                data={testData}
                                currentIndex={currentIndex}
                                myPrivileges={myPrivileges}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                                onUpdate={handleUpdate}
                            />
                        )}
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default AiTestTracker;