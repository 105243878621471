import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Header from "../../header/Header";
import { urls } from "../../../services/UrlList";
import axios from "axios";
import moment from "moment";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./InternaltimerStyle.css";
import MaterialIcon from "material-icons-react";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import BoardUsers from "./BoardUsers";
import BoardUserTeams from "./BoardUserTeams";
export default class AssignmentsandActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      isActive: false,
      selectedUser: 0,
      selectedUserName: "",
      tasks: [],
      orginalTasks: [],
      fullTeams: [],
      pmTeamLeads: [],
      pmTeamLead: 0,
      progressBar: true,
      showAllPeople: 1,
      showPeopleType: 1,
      showOnebyOneProgress: false,
      selectedPL: 0,
      usersCount: 0,
      stopRunningSearch: false,
      orginalUsers: [],
      groupTasks: [],
      orginalGroupTasks: [],
      selectedTeamParam: "",
      selectedTeam: "",
      individualFilterActive: "",
      groupFilterActive: "",
      internalFilterActive: false,
      showMenu: true,
    };
    // this.keyPress = this._handleKeyDown.bind(this);
  }
  componentDidMount = () => {
    document.title = "KIMPHUB - Assignments and Activities";
    this.checkUserPrivilege();
    this.getAllTeams();
    this.getPMTeamLeads();
    if (this.props.match.params.team && this.props.match.params.team != "") {
      this.setState({ selectedTeamParam: this.props.match.params.team });
    } else {
      const _this = this;
      setTimeout(() => {
        _this.checkPlate();
      }, 1000);
    }
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then((response) => response.json())
      .then((response) => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then((response) => response.json())
          .then((response) => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch((error) => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") !== null) {
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myTimezone: login.my_timezone,
      });
    }
  };

  checkPlate = () => {
    this.setState({
      progressBar: true,
      individualFilterActive: "",
      groupFilterActive: "",
    });
    const data = {
      user_id:
        this.state.selectedUser > 0 ? this.state.selectedUser : this.state.myId,
    };
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.plateTasks, data, {
        headers: headers,
      })
      .then((response) => {
        this.setState({
          tasks: response.data,
          orginalTasks: response.data,
          progressBar: false,
        });
      })
      .catch((error) => {
        this.setState({ progressBar: false });
      });
  };

  checkPlateAll = (userid, username) => {
    const data = {
      user_id: userid,
    };
    let temp = [];
    let lists = [...this.state.groupTasks];
    temp["username"] = username;
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-origin": "*",
    };
    axios
      .post(urls.plateTasks, data, {
        headers: headers,
      })
      .then((response) => {
        temp["tasks"] = response.data;
        lists.push(temp);
        //console.log(lists);
        this.setState({ groupTasks: lists, orginalGroupTasks: lists });
      })
      .catch((error) => {});
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  onPeopleChange = (user) => {
    this.setState({
      selectedUser: user.id,
      selectedUserName: user.name,
      individualFilterActive: "",
      groupFilterActive: "",
    });
    const _this = this;
    setTimeout(() => {
      _this.checkPlate();
    }, 500);
  };

  onTeamChange = (teamid) => {
    if (teamid > 0) {
      this.setState({
        selectedTeam: teamid,
        tasks: [],
        orginalTasks: [],
        groupTasks: [],
        orginalGroupTasks: [],
        showOnebyOneProgress: true,
        selectedPL: "",
        individualFilterActive: "",
        groupFilterActive: "",
      });
      const _this = this;
      setTimeout(() => {
        _this.getAllUsers(teamid, "");
      }, 100);
    } else {
      this.setState({
        selectedTeam: "",
        tasks: [],
        orginalTasks: [],
        groupTasks: [],
        orginalGroupTasks: [],
        showOnebyOneProgress: false,
        selectedPL: "",
      });
      this.checkPlate();
    }
  };

  getAllUsers = (selectedTeam, selectedPL) => {
    let opts = {
      team_id: selectedTeam > 0 ? selectedTeam : 0,
      pl_id: selectedPL > 0 && selectedPL,
      user_id: this.state.myId,
    };
    //console.log(opts);
    var fUrl = urls.getTeamMembers;
    fetch(fUrl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.length > 0) {
          this.setState({
            orginalUsers: response,
            usersCount: response.length,
          });
          //return false;
          const _this = this;
          setTimeout(() => {
            var lcount = _this.loopThroughUsers(0);
          }, 300);
        } else {
          this.setState({ showOnebyOneProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showOnebyOneProgress: false });
      });
  };

  loopThroughUsers = (count) => {
    var lcount = count;
    const _this = this;
    this.setState({
      stopRunningSearch: false,
      progressBar: false,
    });
    setTimeout(() => {
      if (lcount === _this.state.usersCount || this.state.stopRunningSearch) {
        this.setState({
          showOnebyOneProgress: false,
          stopRunningSearch: false,
          progressBar: false,
        });
        return;
      }
      _this.checkPlateAll(
        _this.state.orginalUsers[lcount].id,
        _this.state.orginalUsers[lcount].name
      );
      lcount++;
      _this.loopThroughUsers(lcount);
    }, 1600);
  };

  getAllTeams = async () => {
    var fUrl = urls.getFullTeams;
    fetch(fUrl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          this.findMatchingTeam(response);
          this.setState({
            fullTeams: response,
          });
        }
      });
  };

  findMatchingTeam = async (fullteam) => {
    var itemkey = fullteam
      .map(function (item) {
        return item.name.replace(/\s+/g, "-").toLowerCase();
      })
      .indexOf(this.state.selectedTeamParam.toLowerCase());
    if (itemkey >= 0) {
      let teamid = fullteam[itemkey].id;
      this.onTeamChange(teamid);
    }
  };

  getPMTeamLeads = () => {
    fetch(urls.getPMTeamLeads, {
      headers: {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          pmTeamLeads: response[0].pmtls,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = ({ target }) => {
    if (target.value > 0) {
      let fullTeams = [...this.state.fullTeams];
      this.setState({
        fullTeams: [],
        individualFilterActive: "",
        groupFilterActive: "",
      });
      this.setState({
        selectedTeam: "",
        tasks: [],
        orginalTasks: [],
        groupTasks: [],
        orginalGroupTasks: [],
        showOnebyOneProgress: true,
        selectedPL: target.value,
      });

      const _this = this;
      setTimeout(() => {
        _this.setState({ fullTeams: fullTeams });
        _this.getAllUsers("", target.value);
      }, 100);
    } else {
      this.setState({
        selectedTeam: "",
        tasks: [],
        orginalTasks: [],
        groupTasks: [],
        orginalGroupTasks: [],
        showOnebyOneProgress: false,
        selectedPL: "",
        individualFilterActive: "",
        groupFilterActive: "",
      });
      this.checkPlate();
    }
  };

  fetchPLTasks = (pl) => {
    let fullTeams = [...this.state.fullTeams];
    this.setState({ fullTeams: [] });
    this.setState({
      selectedTeam: "",
      tasks: [],
      orginalTasks: [],
      groupTasks: [],
      orginalGroupTasks: [],
      showOnebyOneProgress: true,
      selectedPL: pl,
    });

    const _this = this;
    setTimeout(() => {
      _this.setState({ fullTeams: fullTeams });
      _this.getAllUsers("", pl);
    }, 100);
  };

  refreshTasks = () => {
    if (this.state.selectedPL > 0) {
      this.fetchPLTasks(this.state.selectedPL);
    } else if (this.state.selectedTeam > 0) {
      this.onTeamChange(this.state.selectedTeam);
    } else {
      this.checkPlate();
    }
  };

  filterTasks = (type, action) => {
    if (type === "individual") {
      let temp = [...this.state.orginalTasks];
      let list = [];
      this.setState({
        individualFilterActive: action,
        internalFilterActive: false,
      });
      if (action === "All") {
        this.setState({ tasks: temp });
      } else {
        temp.forEach((p1) => {
          if (p1.due_type === action) {
            list.push(p1);
            if (action === "Internal") {
              this.setState({ internalFilterActive: true });
            }
          }
        });
        this.setState({ tasks: list });
      }
    } else {
      let temp = [...this.state.orginalGroupTasks];
      let list1 = [];
      this.setState({ groupFilterActive: action, internalFilterActive: false });
      if (action === "All") {
        this.setState({ groupTasks: temp });
      } else {
        temp.forEach((p1) => {
          let list2 = [];
          list2.username = p1.username;
          list2.tasks = [];
          if (p1.tasks && p1.tasks.length > 0) {
            p1.tasks.forEach((p2) => {
              if (p2.due_type === action) {
                list2.tasks.push(p2);
                if (action === "Internal") {
                  this.setState({ internalFilterActive: true });
                }
              }
            });
          }
          list1.push(list2);
        });
        this.setState({ groupTasks: list1 });
      }
    }
  };

  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
        <Container
          fluid
          className={
            this.state.myPrivileges.includes("i_am_designer")
              ? "top-bar-active"
              : "top-bar-inactive"
          }
        >
          <Row>
            <div className="theme_header">
              <Header
                {...this.props}
                classname="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={true}
              />
            </div>
            <div className="SideNavbar">
              <SideMenu
                activeitem="project"
                showmenu={this.state.showMenu}
                hidemenu={this.hideMenu.bind(this)}
              />
            </div>
            <div className="themeContentBody">
              <Row>
                <Col sm={12} lg={12}>
                  <Card className="switch_user_card">
                    <div className="d-flex justify-content-between">
                      <CardTitle tag="h1">
                        All the Latest Assignments and Activities
                      </CardTitle>
                      {!this.state.showOnebyOneProgress &&
                        !this.state.progressBar && (
                          <span
                            className="refresh-assignments"
                            onClick={this.refreshTasks}
                          >
                            <MaterialIcon icon="refresh" />
                          </span>
                        )}
                    </div>
                    <Row>
                      <Col className="d-flex plate-filters">
                        <div className="laa_search_filter" id="filter">
                          <BoardUsers
                            myid={this.state.myId}
                            baseid={this.state.myBaseId}
                            teamid={this.state.userTeam}
                            usertype={this.state.showPeopleType}
                            onPeopleChange={this.onPeopleChange.bind(this)}
                            showall={this.state.showAllPeople}
                          />
                          <Button className="search_icon_laa">
                            {" "}
                            <MaterialIcon icon="search" />
                          </Button>
                        </div>
                        <div className="d-flex group-filter-select">
                          {this.state.fullTeams &&
                            this.state.fullTeams.length > 0 && (
                              <BoardUserTeams
                                myid={this.state.myId}
                                baseid={this.state.myBaseId}
                                teamid={this.state.selectedTeam}
                                usertype={this.state.showPeopleType}
                                onteamchange={this.onTeamChange}
                                showall={this.state.showAllPeople}
                                fullteam={this.state.fullTeams}
                                selectedteam={this.state.selectedTeam}
                              />
                            )}
                          {this.state.pmTeamLeads &&
                            this.state.pmTeamLeads.length > 0 && (
                              <select
                                name="selectedPL"
                                className="form-control pmTeamLeadSelector"
                                onChange={this.handleChange}
                              >
                                <option value="">Select PL</option>
                                {this.state.pmTeamLeads.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.id}
                                      selected={
                                        this.state.selectedPL == item.id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                        </div>
                      </Col>
                    </Row>
                    {this.state.orginalTasks &&
                      this.state.orginalTasks.length > 0 && (
                        <div className="d-flex tablist">
                          <div className="tablistleftbox">
                            <span
                              className={
                                this.state.individualFilterActive === "All" &&
                                "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "All"
                              )}
                            >
                              All
                            </span>
                            <span
                              className={
                                this.state.individualFilterActive ===
                                  "Overdue" && "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "Overdue"
                              )}
                            >
                              Overdue
                            </span>
                            <span
                              className={
                                this.state.individualFilterActive === "Today" &&
                                "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "Today"
                              )}
                            >
                              Today
                            </span>
                            <span
                              className={
                                this.state.individualFilterActive ===
                                  "Tomorrow" && "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "Tomorrow"
                              )}
                            >
                              Tomorrow
                            </span>
                            <span
                              className={
                                this.state.individualFilterActive ===
                                  "Upcoming" && "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "Upcoming"
                              )}
                            >
                              Upcoming
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                this.state.individualFilterActive ===
                                  "Internal" && "active"
                              }
                              onClick={this.filterTasks.bind(
                                this,
                                "individual",
                                "Internal"
                              )}
                            >
                              Internal Tasks
                            </span>
                          </div>
                        </div>
                      )}
                    {!this.state.showOnebyOneProgress &&
                      this.state.orginalGroupTasks &&
                      this.state.orginalGroupTasks.length > 0 && (
                        <Row>
                          <Col sm="10">
                            <div className="plate-filters d-flex">
                              <span
                                className={
                                  this.state.groupFilterActive === "All" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "All"
                                )}
                              >
                                All
                              </span>
                              <span
                                className={
                                  this.state.groupFilterActive === "Overdue" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "Overdue"
                                )}
                              >
                                Overdue
                              </span>
                              <span
                                className={
                                  this.state.groupFilterActive === "Today" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "Today"
                                )}
                              >
                                Today
                              </span>
                              <span
                                className={
                                  this.state.groupFilterActive === "Tomorrow" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "Tomorrow"
                                )}
                              >
                                Tomorrow
                              </span>
                              <span
                                className={
                                  this.state.groupFilterActive === "Upcoming" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "Upcoming"
                                )}
                              >
                                Upcoming
                              </span>
                            </div>
                          </Col>
                          <Col sm="2">
                            <div className="plate-filters-right">
                              <span
                                className={
                                  this.state.groupFilterActive === "Internal" &&
                                  "active"
                                }
                                onClick={this.filterTasks.bind(
                                  this,
                                  "group",
                                  "Internal"
                                )}
                              >
                                Internal Tasks
                              </span>
                            </div>
                          </Col>
                        </Row>
                      )}
                    <Tabs className="all_assignment_activity_card d-flex">
                      {/* <TabList>
                  <Tab>Someone's Assignments</Tab>
                  <Tab>Someone's Activity</Tab>
                </TabList> */}
                    </Tabs>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={12}>
                  <Card className="switch_user_card your_plate_card plate_inner_wrap">
                    <LoadingWrapper isLoading={this.state.progressBar}>
                      {this.state.tasks && this.state.tasks.length > 0 && (
                        <>
                          <CardTitle tag="h1">
                            {this.state.selectedUserName != "" ? (
                              <>{`Here’s what’s on the plate of ${this.state.selectedUserName}`}</>
                            ) : (
                              <>Here’s what’s on your plate</>
                            )}
                          </CardTitle>
                          <Row>
                            {this.state.tasks.map((task, index) => {
                              return (
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={4}
                                  className={`${
                                    task.board_type === 2 && "internal-taskitem"
                                  } ${
                                    this.state.internalFilterActive
                                      ? "active"
                                      : ""
                                  } `}
                                >
                                  <a
                                    target="_blank"
                                    className={`single-task-link`}
                                    href={
                                      "/task/detail/" +
                                      task.bid_cpt +
                                      "/" +
                                      task.tid_cpt +
                                      "?uuid=" +
                                      this.state.myId
                                    }
                                  >
                                    <div className="plate_card">
                                      <span className="task-client-detail-plate">
                                        {task.project_name}
                                      </span>
                                      <span className="task-project-name-palate">
                                        {task.task_name}
                                      </span>
                                      <span className="date_and_time_platecard">
                                        <MaterialIcon icon="event" />
                                        {task.due_date !== "" &&
                                          moment(
                                            new Date(task.due_date).getTime()
                                          )
                                            .tz(this.state.myTimezone)
                                            .format("MMM, DD YYYY h:mm a")}
                                      </span>
                                      <span className="labels-priority">
                                        {task.labels &&
                                          task.labels.length > 0 && (
                                            <>
                                              {task.labels.map(
                                                (label, index) => {
                                                  return (
                                                    <span
                                                      className={`task-label ${label.name.toLowerCase()}`}
                                                    >
                                                      {label.name}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        {task.task_priority === 1 && (
                                          <span className="task-priority">
                                            Priority
                                          </span>
                                        )}
                                        {task.task_approval_waiting != 0 && (
                                          <span className="task-priority tba">
                                            TBA
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      )}

                      {this.state.groupTasks &&
                        this.state.groupTasks.length > 0 && (
                          <>
                            {this.state.groupTasks.map((task, index) => {
                              return (
                                <Row>
                                  <>
                                    <Col xs={12} md={12}>
                                      <CardTitle tag="h1">
                                        {task.username != "" && (
                                          <>{`Here’s what’s on the plate of ${task.username}`}</>
                                        )}
                                      </CardTitle>
                                    </Col>
                                    {task.tasks && task.tasks.length > 0 ? (
                                      <>
                                        {task.tasks.map((sitem, index) => {
                                          return (
                                            <Col
                                              xs={12}
                                              sm={6}
                                              md={4}
                                              className={`${
                                                sitem.board_type === 2 &&
                                                "internal-taskitem"
                                              } ${
                                                this.state.internalFilterActive
                                                  ? "active"
                                                  : ""
                                              } `}
                                            >
                                              <a
                                                target="_blank"
                                                className={`single-task-link`}
                                                href={
                                                  "/task/detail/" +
                                                  sitem.bid_cpt +
                                                  "/" +
                                                  sitem.tid_cpt +
                                                  "?uuid=" +
                                                  this.state.myId
                                                }
                                              >
                                                <div className="plate_card">
                                                  <span className="task-client-detail-plate">
                                                    {sitem.project_name}
                                                  </span>
                                                  <span className="task-project-name-palate">
                                                    {sitem.task_name}
                                                  </span>
                                                  <span className="date_and_time_platecard">
                                                    <MaterialIcon icon="event" />
                                                    {sitem.due_date !== "" &&
                                                      moment(
                                                        new Date(
                                                          sitem.due_date
                                                        ).getTime()
                                                      )
                                                        .tz(
                                                          this.state.myTimezone
                                                        )
                                                        .format(
                                                          "MMM, DD YYYY h:mm a"
                                                        )}
                                                  </span>
                                                  <span className="labels-priority">
                                                    {sitem.labels &&
                                                      sitem.labels.length >
                                                        0 && (
                                                        <>
                                                          {sitem.labels.map(
                                                            (label, index) => {
                                                              return (
                                                                <span
                                                                  className={`task-label ${label.name.toLowerCase()}`}
                                                                >
                                                                  {label.name}
                                                                </span>
                                                              );
                                                            }
                                                          )}
                                                        </>
                                                      )}
                                                    {sitem.task_priority ===
                                                      1 && (
                                                      <span className="task-priority">
                                                        Priority
                                                      </span>
                                                    )}
                                                    {sitem.task_approval_waiting !=
                                                      0 && (
                                                      <span className="task-priority tba">
                                                        TBA
                                                      </span>
                                                    )}
                                                  </span>
                                                </div>
                                              </a>
                                            </Col>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <Col>
                                        <div className="no-plate-task">
                                          No task on plate
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                </Row>
                              );
                            })}
                          </>
                        )}
                    </LoadingWrapper>
                    <LoadingWrapper
                      isLoading={this.state.showOnebyOneProgress}
                    ></LoadingWrapper>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
