import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "reactstrap";
import { useGetKimpMembers } from "../ProfileReport/useProfileReportHooks";
import MonthSelector from "./MonthSelector";
const AiReportFilters = ({
  month,
  userType,
  onMonthChange,
  updateSelectedMember,
}) => {
  const [member, setMember] = useState(0);
  const [viewMonth, setViewMonth] = useState(month);

  const { isLoading: usersLoading, data: usersData } =
    useGetKimpMembers(userType);

  const handleInputChange = (input, e) => {
    console.log("value", input);
  };

  const handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if (size > 0) {
      setMember(selectedOptions[0].id);
      updateSelectedMember(selectedOptions[0].id);
    }else
    {
        setMember(0);
        updateSelectedMember(0);
    }
  };

  useEffect(() => {
    setViewMonth(month);
  }, [month]);

  const changeViewMonth = (value) => {
    setViewMonth(value);
    onMonthChange(value);
  };

  return (
    <div className="row performance-report-head-filter">
      <div className="col-md-12 d-flex head-items">
        <h2>AI Tools Knowledge Test Tracking</h2>
        <div className="d-flex header-right-wrap">
          {/* <Input
            type="select"
            name="time-period"
            className="graph-period head-filter-month monthsort_select"
            onChange={(e) => changeViewMonth(e.target.value)}
          >
            <option value="1" selected={month == 1 && "selected"}>
              Last Month
            </option>
            <option value="0" selected={month == 0 && "selected"}>
              Current Month
            </option>
          </Input> */}
          <MonthSelector 
            month={month} 
            onChange={(value) => changeViewMonth(value)} 
          />
          {!usersLoading && (
            <Typeahead
              id="user-picker"
              labelKey="name"
              onInputChange={handleInputChange}
              onChange={handleChange}
              options={usersData?.data.users}
              placeholder="Choose member"
              className="head-filter-member-fa"
              selected=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AiReportFilters;
