import React, { Component, Fragment } from "react";
import Header from "../header/Header";
import "./list-head.css";
import "./listcard.css";
import "./LogTimer.css";
import { urls } from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Input,
  Button,
  CardTitle,
  Table
} from "reactstrap";
import BasecampIcon from "../svgicon/BasecampIcon";
import TrelloIcon from "../svgicon/TrelloIcon";
import { isMobile } from "react-device-detect";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { motion } from "framer-motion";
import LogFilter from "../filter/LogFilter";
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import { getPMTeamLeads } from "../filter/FilterFunctions";

export default class IssueReportingLog extends Component {
  constructor(props) {
    super(props);
    var s = new Date();
    s.setDate(s.getDate());
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("DD-MM-YYYY");
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      showProgress: false,
      showProgressSearch: false,
      orginalEntries: [],
      fullEntries: [],
      selectedUser: "",
      issueCount:'',
      selectedCreator:"",
      selectedProject: "",
      show_detail: false,
      show_detail_id: 0,
      startDate: null,
      endDate: null,
      running_status: 0,
      showMenu: true,
      filterHour: "",
      detailEntries: [],
      DetailView: true,
      showDetailProgress: false,
      project_id: "",
      showTaskPeopleId: "",
      showTaskPeople: "",
      pmTeamLeads:[]
    };
  }

  // componentDidMount = () => {
  //   document.title = "HUB - Task Issue Reporting Logs";
  //   this.checkUserPrivilege();
  //   this.renderContentDevice();
  //   const _this = this;
  //   //this.setState({pmTeamLeads: getPMTeamLeads()})
  // };

  async componentDidMount() {
    try {
      document.title = "HUB - Task Issue Reporting Logs";
      this.checkUserPrivilege();
      this.renderContentDevice();
      const pmTeamLeads = await getPMTeamLeads();
      this.setState({ pmTeamLeads });
    } catch (error) {
      console.error('Error fetching PM team leads:', error);
    }
  }


  signOut = () => {
    fetch(urls.logmeout)
      .then((response) => response.json())
      .then((response) => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then((response) => response.json())
          .then((response) => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch((error) => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myBaseId: login.basecampid,
      });

      if(!login.privileges.includes('i_am_pl') && !login.privileges.includes('i_am_com') && !login.privileges.includes('i_am_hr') 
      && !login.privileges.includes('i_am_admin') && !login.privileges.includes('i_am_hradmin') && !login.privileges.includes('i_am_operations') 
      && !login.privileges.includes('i_am_qa') )
      {
        this.props.history.push("/home");
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes("mobile_access")) {
      this.setState({ isMobileView: 1 });
    }
    if (isMobile) {
      this.setState({ showMenu: false });
    }
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  showCard = (entry) => {
    if(entry.tid_cpt !=null)
    window.open("/task/detail/"+entry.bid_cpt+"/"+entry.tid_cpt+"?uuid="+this.state.myId,'_blank');
  };

  showDetailEntry = (id) => {
    this.setState({
      show_detail: true,
      show_detail_date: 0,
      show_detail_id: this.state.show_detail_id === id ? 0 : id,
    });
  };

  searchEntriestwo = ( user, creator, start, end,team, pl) => {
    this.setState({
      orginalEntries: [],
      showProgress: true,
      selectedUser: user,
      selectedCreator: creator,
      startDate: start != "" ? start.format("YYYY-MM-DD") : "",
      endDate: end != "" ? end.format("YYYY-MM-DD") : "",
    });

      let opts = {
        member: pl>0 ? 0 : user,
        creator: creator,
        start_date: start != "" ? start.format("YYYY-MM-DD") : "",
        end_date: end != "" ? end.format("YYYY-MM-DD") : "",
        pl_id:pl,
        team_id: pl>0? 0 : team
      };

      fetch(urls.issueReportingLog, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            orginalEntries: response["logs"],
            fullEntries: response["logs"],
            showProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
  };

  filterbyhour = (value) => {
    this.setState({
      filterHour: value,
    });
    this.filterByHours(value);
  };

  filterByHours = (value) => {
    this.setState({ filterHour: value });
    if (value != "") {
      setTimeout(() => {
        let temp = [];
        temp = this.state.fullEntries;
        let list = [];
        temp.forEach((p1) => {
          if (p1.total_hr > parseInt(this.state.filterHour)) {
            list.push(p1);
          }
        });
        this.setState({ orginalEntries: list });
      }, 100);
    } else {
      this.setState({ orginalEntries: this.state.fullEntries });
    }
  };

  timeConvert = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
  };

  replaceText = (str) =>
  {
    let result='';
   
    result = str.replace("You are ", "");
   
    return result ;  
  }

  stripslashes = (str) => {
    if (str != null) {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
    }
    return str;
  }

  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        },
      },
      hidden: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        },
      },
    };

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    };

    let deviceWidth = window.innerWidth;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
        <Container
          fluid
          className={
            (this.state.myPrivileges.includes("i_am_designer")
              ? "top-bar-active"
              : "top-bar-inactive") + " project-report"
          }
        >
          <Row>
            <div className="theme_header">
              <Header
                {...this.props}
                classname="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={true}
              />
            </div>
            <div className="SideNavbar">
              <SideMenu
                activeitem="report"
                showmenu={this.state.showMenu}
                hidemenu={this.hideMenu.bind(this)}
              />
            </div>
            <div className="themeContentBody late-coming-wrapper">
              <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">
                Task Issue Reporting Logs
                </CardTitle>
                <div className="filter_header_wrap">
                  {(this.state.pmTeamLeads && this.state.pmTeamLeads.length>0) &&
                  (
                    <LogFilter
                    filterbytask={this.filterbytask}
                    filterbyhour={this.filterbyhour}
                    searchEntriesone={this.searchEntriestwo}
                    pmTeamLeads = {this.state.pmTeamLeads}
                    showteamfilter={true}
                    usertype={'id'}
                    userslist={1}
                    userlabel1={'Resonsible Person'}
                    userlabel2= {(this.state.myPrivileges.includes("special_hidden_views") || this.state.myPrivileges.includes("i_am_operations"))  ?  'Created By' : ''}
                    issuecount={(this.state.orginalEntries && this.state.orginalEntries.length>0 ? this.state.orginalEntries.length: '')}
                  />
                  )}
                </div>
                 <>
                    <Table responsive className="theme_table">
                      <thead>
                        <tr>
                          <th width="20%">Issue Type</th>
                          <th width="20%">Reported on</th>
                          <th width="20%">Reported By</th>
                          <th width="40%">Responsible Persons</th>
                        </tr>
                      </thead>
                        {this.state.showProgress &&
                        (
                          <LoadingWrapper
                          LoaderCount={5}
                              isLoading={this.state.showProgress}
                          >
                            <tr><td colSpan={4}></td></tr>
                          </LoadingWrapper>
                        )}
                      <tbody>
                         {this.state.orginalEntries && this.state.orginalEntries.length>0 ? (
                            <>
                                {this.state.orginalEntries.map((entries, index) => {
                                    return ( 
                                    <>
                                    <tr onClick={this.showDetailEntry.bind(this,entries.id)}>
                                        <td width="20%">{entries.issue_name}</td>
                                        <td width="20%">{entries.log_time}</td>
                                        <td width="20%">{entries.name}</td>
                                        <td width="40%">
                                            {
                                            entries.responsible_persons!='[]' &&
                                            (
                                                <div className="issue-responsibile-persons"> 
                                                {(JSON.parse(entries.responsible_persons)).length>0 && JSON.parse(entries.responsible_persons).map((person, index)=>
                                                {
                                                    return(
                                                        <span key={index}>{person.name}</span>
                                                    )
                                                })}
                                                </div>
                                            )}
                                        </td>
                                        </tr>

                                        {(this.state.show_detail && this.state.show_detail_id === entries.id ) && (
                                        <>
                                        <LoadingWrapper
                                            LoaderCount={5}
                                            isLoading={
                                            this.state
                                                .showDetailProgress
                                            }
                                        ></LoadingWrapper>

                                        <tr class="sub-level">
                                            {(this.state.show_detail_id === entries.id) && (
                                                <td colspan="12" className="p0">
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr>
                                                            <th>Comments</th>
                                                            <th>Hub card link</th>
                                                            <th>Ticket</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td width="80%" className="issue-task-comment">
                                                                    <Linkify className="cmd_editer_preview" options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>
                                                                        {ReactHtmlParser(this.stripslashes(entries.comment) + " ")}
                                                                    </Linkify>
                                                                </td>
                                                                <td className="hub-card-link" onClick={this.showCard.bind(this,entries)}>
                                                                    {'Link'}
                                                                </td>
                                                                <td className="hub-card-link">
                                                                  {entries.ticket_id !=null &&
                                                                  (
                                                                    <a href={`${urls.notificationUrl}/ticket/${entries.ticket_id}`} target="_blank">Link</a>
                                                                  )}
                                                                </td>
                                                            </tr>         
                                                        </tbody>
                                                    </table>
                                            </td>
                                            )}
                                        </tr>
                                        </>
                                        )}  

                                    </>
                                    )} 
                                )} 
                                </>         
                        ):
                        (
                            <>
                              {!this.state.showProgress &&
                              (
                                <>
                                    {this.state.selectedUser!='' || this.state.startDate!=null || this.state.endDate!=null ?
                                      (
                                          <tr><td colspan="4"><div className="item-msg-center-text">{'No result found.'}</div></td></tr>
                                      ):
                                      (
                                          <tr><td colspan="4"><div className="item-msg-center-text">{'Please choose options and search to see results.'}</div></td></tr>
                                      )}
                                </>
                              )}
                            </>
                        )}
                     </tbody>
                </Table>
                </>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
