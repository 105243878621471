import React, { Component, Fragment } from "react";
import "./LoanSummeryList.css";
import { Card } from "reactstrap";

export default class LoanSummeryList extends Component {
  constructor(props) {
    super(props);   
  }

  processReview = (loan_id, action, loan_amount, user_id) =>
  {
      this.props.processreview(loan_id, action, loan_amount, user_id);
  }
render() {
    return (
      <>
      <tbody className={this.props.item.status === 0? "loan-summery-list-item waiting-approval" : (this.props.item.status === 2? "loan-summery-list-item rejected-approval" : 'loan-summery-list-item') } >
        <tr>
          <td>{this.props.item.name}</td>
          <td>{this.props.item.installment_date}</td>
          <td className="loan-description-text">
          {this.props.item.debit>0?
          (
            <>
              <span className="repayment-via">{"Repayment done via "+this.props.item.repay_type}</span>
              <span className="repay-data">{this.props.item.installment_note}</span>
           </>

          )
          :
          (
            <>
            {this.props.item.loan_repay_type != null?
              (
                <>
                 <span>{this.props.item.installment_note}</span>
                 <span className="repay-data">{this.props.item.loan_repay_type === 1? "Rs."+this.props.item.credit+" - One time / On: "+this.props.item.one_time_payment_date : "Rs."+this.props.item.monthly_amount + " / Monthly / " +"Start Date: "+this.props.item.installment_start_date}</span>
                 </>
              )
              :
              (
                   <>
                      <span>{this.props.item.installment_note}</span>
                      <span className="repay-data"></span>
                   </>
              )
            }           
            </>
          )         
          }
          </td>
          <td>
          {this.props.item.debit>0?
          (
            <>
              {this.props.item.status !==3 ? this.props.item.currency+" "+this.props.item.debit : this.props.item.debit}
            </>
          )
          :
          (
            <>
              {this.props.item.status !==3 ? this.props.item.currency+" "+this.props.item.credit : this.props.item.credit}
            </>
          )         
          }
          </td>
          {this.props.pending !== '1' ? 
        (
          <>
          <td>
           
              {this.props.item.debit>0?
              (
                "Repay"
              )
              :
              (
                "Loan"
              )         
              }
        
          </td>
          <td>
          
          {this.props.item.status === 0? "pending": (this.props.item.status === 1? "Approved": (this.props.item.status===3? 'Old Record' : 'Rejected'))}
        
          </td>
          </>
        ):
        (
          <>
            {this.props.item.loan_repay_type === 1?
            (
               <>
                <td>Loan</td> 
                <td className="lstatus">
                {this.props.item.status === 0? "pending": (this.props.item.status === 1? "Approved": (this.props.item.status===3? 'Old Record' : 'Rejected'))}
                  </td> 
               </>
            )
            :
            (
              <>
                <td>Loan</td>
                <td className="lstatus">
                {this.props.item.status === 0? "pending": (this.props.item.status === 1? "Approved": (this.props.item.status===3? 'Old Record' : 'Rejected'))}
                </td>
              </>
            )
            }
            </>
        )
        }
        {this.props.item.status=== 0 ?
          (
             <td className="actions_btn">
              
                &nbsp; &nbsp;<div class="approve" onClick={this.processReview.bind(this, this.props.item.event_id, 1, this.props.item.credit, this.props.item.user_id)} >A</div> &nbsp; &nbsp;
                <div class="reject" onClick={this.processReview.bind(this, this.props.item.event_id, 2, this.props.item.credit, this.props.item.user_id)}>R</div>
              
             </td> 
          ):
          (
            <td className="actions_btn"></td>
          )
        }  
        </tr>
      </tbody>
    
      </>
    );
  }

}
