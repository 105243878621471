import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Input, Col } from "reactstrap";
import "./ProfileReport.css";
import MaterialIcon from "material-icons-react";
import {
  useGetProfileReportTasks,
  useGetMyPerformanceData,
} from "./useProfileReportHooks";
import ProfileReportFilters from "./ProfileReportFilters";
import CustomerRatings from "./CustomerRatings";

// Custom hook for lazy loading based on scroll
const useLazyLoadComponent = (ref, distance = 300) => {
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: `${distance}px`,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, distance]);

  return shouldLoad;
};

// Lazy load components
const TaskRatings = lazy(() => import("./TaskRatings"));
const ProfileTaskGraph = lazy(() => import("./ProfileTaskGraph"));
const ProfileReportRadar = lazy(() => import("./ProfileReportRadar"));
const PerformanceMetricsCards = lazy(() => import("./PerformanceMetricsCards"));
const PerformanceChart = lazy(() => import("./PerformanceChart"));
const ProfileTaskIssues = lazy(() => import("./ProfileTaskIssues"));
const ProfileTickets = lazy(() => import("./ProfileTickets"));
const ProfileAiTest= lazy(() => import("./ProfileAiTest"));
const LeaveAnalyticsChart = lazy(() => import("./LeaveAnalyticsChart"));

// Loading fallback component with skeleton loading
const LoadingFallback = () => (
  //   <div className="loading-skeleton p-4">
  //     <div className="animate-pulse">
  //       <div className="h-8 bg-gray-200 rounded w-full mb-4"></div>
  //       <div className="h-64 bg-gray-200 rounded w-full"></div>
  //     </div>
  //   </div>
  <div className="loading-spinner">
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden"></span>
    </div>
  </div>
);

// Wrapper component for lazy loading
const LazyLoadWrapper = ({ children, distance }) => {
  const containerRef = React.useRef(null);
  const shouldLoad = useLazyLoadComponent(containerRef, distance);

  return (
    <div ref={containerRef}>
      {shouldLoad ? (
        <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
      ) : (
        <LoadingFallback />
      )}
    </div>
  );
};

export default function ProfileReport(props) {
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(0);
  const [userType, setUserType] = useState(1);
  const [viewMember, setViewMember] = useState(0);
  const history = useHistory();

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOpenOffcanvas = () => {
    setShowOffcanvas(true);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
      if (login.privileges.includes("access_all_reports")) {
        setUserType(1);
      } else if (login.privileges.includes("access_team_reports")) {
        setUserType(2);
      } else {
        setUserType(3);
      }
    }
  };

  const { isLoading: taskLoading, data: tasksData } = useGetProfileReportTasks(
    month,
    viewMember
  );
  const { data: performaceData, isLoading: performanceLoading } =
    useGetMyPerformanceData(month, viewMember);

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  const getMetricScore = () => {
    let totalScore = 0;
    let totalRatings = 0;
    let aiperformanceScore = 0;
    let performanceScore = 0;
    if (performaceData?.data.performance) {
      performaceData.data.performance.forEach((metric) => {
        totalScore += parseInt(metric.score);
        totalRatings +=  parseInt(metric.rating_count)*10; //parseInt(metric.rating_count);
      });
      performanceScore = (totalScore/totalRatings)*60;

      if(performaceData?.data.aireport[0].score > 0){
        totalScore = performaceData?.data.aireport[0].score;  
        totalRatings = performaceData?.data.aireport[0].rating_count*40;
        aiperformanceScore = (totalScore/totalRatings)*40;
      }
    }
    let result = (((performanceScore) + (aiperformanceScore))/10).toFixed(2);
    //return Number(result).toString();
    //console.log("result",result);
    return result;
  };

  const updateMonth = (value) => {
    setMonth(value);
  };

  const updateSelectedMember = (value) => {
    setViewMember(value);
  };

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody PerformanceReportpage">
            <ProfileReportFilters
              month={month}
              userType={userType}
              onMonthChange={updateMonth}
              updateSelectedMember={updateSelectedMember}
            />

            {month >= 0 && myId > 0 && (
              <Row  className="mt-4">
                {tasksData?.data && (
                  <>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="card profile-card">
                        <div className="profile-card-icon">
                          <MaterialIcon
                            icon="assignment_turned_in"
                            color="#00806C"
                          />
                        </div>
                        <div className="profile-card-data">
                          <span className="profile-number-size-large">
                            {tasksData.data.completed_tasks}
                          </span>
                          <span className="profile-sub-head">
                            Completed Tasks
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="card profile-card">
                        <div className="profile-card-icon">
                          <MaterialIcon
                            icon="assignment_turned_in"
                            color="#00806C"
                          />
                        </div>
                        <div className="profile-card-data">
                          <span className="profile-number-size-large">
                            {tasksData.data.open_tasks}
                          </span>
                          <span className="profile-sub-head">
                            Incomplete Tasks
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="card profile-card">
                        <div className="profile-card-icon">
                          <MaterialIcon
                            icon="assignment_turned_in"
                            color="#00806C"
                          />
                        </div>
                        <div className="profile-card-data">
                          <span className="profile-number-size-large">
                            {tasksData.data.completed_tasks +
                              tasksData.data.open_tasks}
                          </span>
                          <span className="profile-sub-head">Total Tasks</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="card profile-card">
                        <div className="profile-card-icon">
                          <MaterialIcon
                            icon="assignment_turned_in"
                            color="#00806C"
                          />
                        </div>
                        <div
                          className="profile-card-data"
                          onClick={handleOpenOffcanvas}
                        >
                          <span className="profile-number-size-large">
                            {tasksData?.data.task_ratings
                              ? Object.values(
                                  tasksData?.data.task_ratings
                                ).reduce((sum, num) => sum + num, 0)
                              : 0}
                          </span>
                          <span className="profile-sub-head">
                            Client Ratings
                          </span>
                        </div>
                        <CustomerRatings
                          show={showOffcanvas}
                          onClose={handleCloseOffcanvas}
                          month={month}
                          member={viewMember}
                        />
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            )}
            {month >= 0 && myId > 0 && (
              <>
                <Row className="mt-3">
                  <Col xs={12} sm={12} md={7} lg={7} xl={9}>
                    <div className="card profile-graphs">
                      <div className="card-title-filter">
                        <h4>Tasks Report</h4>
                        <span>
                          <Input
                            type="select"
                            name="time-period"
                            className="graph-period monthsort_select"
                            style={{
                              backgroundPosition: "93% 14px",
                            }}
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option
                              value="12"
                              selected={month == 12 && "selected"}
                            >
                              12 Months
                            </option>
                            <option
                              value="6"
                              selected={month == 6 && "selected"}
                            >
                              6 Months
                            </option>
                            <option
                              value="3"
                              selected={month == 3 && "selected"}
                            >
                              3 Months
                            </option>
                            <option
                              value="1"
                              selected={month == 1 && "selected"}
                            >
                              Last Month
                            </option>
                            <option
                              value="1"
                              selected={month == 0 && "selected"}
                            >
                              Current Month
                            </option>
                          </Input>
                        </span>
                      </div>
                      <LazyLoadWrapper distance={400}>
                        <ProfileTaskGraph
                          months={tasksData?.data.task_months}
                          taskcount={tasksData?.data.task_months_data}
                        />
                      </LazyLoadWrapper>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5} xl={3}>
                    {tasksData?.data.task_months && (
                      <div
                        className="card rating-wap"
                        style={{
                          padding: "24px",
                          paddingBottom: "0px",
                          borderRadius: "12px",
                        }}
                      >
                        <LazyLoadWrapper distance={400}>
                          <TaskRatings ratings={tasksData?.data.task_ratings} />
                        </LazyLoadWrapper>
                      </div>
                    )}
                  </Col>
                </Row>
                {/* <div className="row">
                  <div className="col-md-12 profile-cards profile-cards-two-80"></div>
                </div> */}
              </>
            )}

            {month >= 0 && myId > 0 && (
              <Row className="mt-3">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="card profile-graphs">
                    <div className="card-title-filter">
                      <h4>Overall Performance Metrics</h4>
                      <span
                        className="d-flex justify-content-between align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <span className="profile-grey-text">
                          Overall Rating:{" "}
                          <span
                            className="profile-black-text"
                            style={{ fontWeight: "600" }}
                          >
                            {performaceData?.data.performance.length > 0
                              ? (getMetricScore() * 10).toFixed(2)
                              : "N/A"}
                          </span>
                        </span>
                        <Input
                          type="select"
                          name="time-period"
                          className="graph-period"
                          onChange={(e) => setMonth(e.target.value)}
                        >
                          <option
                            value="12"
                            selected={month == 12 && "selected"}
                          >
                            12 Months
                          </option>
                          <option value="6" selected={month == 6 && "selected"}>
                            6 Months
                          </option>
                          <option value="3" selected={month == 3 && "selected"}>
                            3 Months
                          </option>
                          <option value="1" selected={month == 1 && "selected"}>
                            Last Month
                          </option>
                          <option value="0" selected={month == 0 && "selected"}>
                            Current Month
                          </option>
                        </Input>
                      </span>
                    </div>
                    <Row className="mt-4">
                      <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <LazyLoadWrapper distance={400}>
                          {month >= 0 && !performanceLoading && (
                            <div style={{ marginTop: "-40px" }}>
                              <ProfileReportRadar score={getMetricScore()} />
                            </div>
                          )}
                        </LazyLoadWrapper>
                      </Col>
                      <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="card grey-card overallviewcard">
                          <LazyLoadWrapper distance={400}>
                            {month >= 0 && !performanceLoading && (
                              <PerformanceMetricsCards
                                performaceData={performaceData}
                                month={month}
                                member={viewMember}
                              />
                            )}
                          </LazyLoadWrapper>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                {/* <div className="col-md-12 profile-cards">
                  <div className="card profile-graphs">
                    <div className="profile-cards profile-cards-two-40">
                      <div></div>
                    </div>
                  </div>
                </div> */}
              </Row>
            )}

            {month >= 0 && myId > 0 && (
              <>
                <Row className="mt-3">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="card profile-graphs">
                      <div className="card-title-filter">
                        <h4>Overall Tracking Metrics</h4>
                        <span
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <Input
                            type="select"
                            name="time-period"
                            className="graph-period"
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option
                              value="12"
                              selected={month == 12 && "selected"}
                            >
                              12 Months
                            </option>
                            <option
                              value="6"
                              selected={month == 6 && "selected"}
                            >
                              6 Months
                            </option>
                            <option
                              value="3"
                              selected={month == 3 && "selected"}
                            >
                              3 Months
                            </option>
                            <option
                              value="1"
                              selected={month == 1 && "selected"}
                            >
                              Last Month
                            </option>
                            <option
                              value="0"
                              selected={month == 0 && "selected"}
                            >
                              Current Month
                            </option>
                          </Input>
                        </span>
                      </div>
                      <LazyLoadWrapper distance={100}>
                        <PerformanceChart month={month} member={viewMember} />
                      </LazyLoadWrapper>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            

            {month >= 0 && myId > 0 && (
              <>
                <Row className="mt-3">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="card profile-graphs">
                      <div className="card-title-filter">
                        <h4>Ai Tools Knowledge</h4>
                        <span
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "10px" }}
                        >
                          <Input
                            type="select"
                            name="time-period"
                            className="graph-period"
                            onChange={(e) => setMonth(e.target.value)}
                          >
                            <option
                              value="12"
                              selected={month == 12 && "selected"}
                            >
                              12 Months
                            </option>
                            <option
                              value="6"
                              selected={month == 6 && "selected"}
                            >
                              6 Months
                            </option>
                            <option
                              value="3"
                              selected={month == 3 && "selected"}
                            >
                              3 Months
                            </option>
                            <option
                              value="1"
                              selected={month == 1 && "selected"}
                            >
                              Last Month
                            </option>
                            <option
                              value="0"
                              selected={month == 0 && "selected"}
                            >
                              Current Month
                            </option>
                          </Input>
                        </span>
                      </div>
                      <LazyLoadWrapper distance={100}>
                        <ProfileAiTest month={month} member={viewMember} />
                      </LazyLoadWrapper>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {/* Conditionally rendered sections */}
            {month >= 0 && myId > 0 && (
              <LazyLoadWrapper distance={10}>
                <ProfileTaskIssues
                  month={month}
                  userid={myId}
                  member={viewMember}
                />
              </LazyLoadWrapper>
            )}

            {month >= 0 && myId > 0 && (
              <LazyLoadWrapper distance={10}>
                <ProfileTickets
                  month={month}
                  userid={myId}
                  member={viewMember}
                />
              </LazyLoadWrapper>
            )}

            {month >= 0 && myId > 0 && (
              <LazyLoadWrapper distance={10}>
                <LeaveAnalyticsChart
                  month={month}
                  changeMonth={updateMonth}
                  member={viewMember}
                />
              </LazyLoadWrapper>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
}
