import React, { useState, useEffect } from 'react';
import {useGetProfileTaskRatingsList} from './useProfileReportHooks';

import './CustomerRatings.css';

const CustomerRatings = ({ show, onClose, month, member }) => {
    const [selectedMember, setSelectedMember] = useState([]);
    const { data: ratingsListData, isLoading: ratingsLoading } = useGetProfileTaskRatingsList(month, member);

    useEffect(() => {
        setSelectedMember(member);
    }, [member]);
    return (
        <>
          <div
            className={`offcanvas offcanvas-start ${show ? 'show' : ''}`}
            tabIndex="-1"
            aria-labelledby="offcanvasLabel"
            style={{ visibility: show ? 'visible' : 'hidden' }}
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasLabel">
                Client Ratings
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="offcanvas-body">
              <p>
              {ratingsLoading ?
                (
                    <div className="d-flex justify-content-center align-items-center text-center mt-5 saving-feedback">
                        <div className="spinner-border text-success" role="status">
                        </div>
                        <span className="visually-hidden">Please wait</span>
                    </div>
                ):
                (
                    <div>
                        {ratingsListData?.data.task_ratings_list && ratingsListData.data.task_ratings_list.length > 0 ? (
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Task Name</th>
                                        <th>Rating</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ratingsListData.data.task_ratings_list.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.task_name}</td>
                                            <td>{item.star_rated}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center mt-5">
                                <p>No data found</p>
                            </div>
                        )}
                    </div>
                )
              }
              </p>
            </div>
          </div>
          {show && (
            <div
              className="offcanvas-backdrop fade show"
              onClick={onClose}
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1040,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            ></div>
          )}
        </>
      );
    };

export default CustomerRatings;


