import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card } from "reactstrap";
import "./ProfileReport.css";
import { useGetProfileReportMembers } from "./useProfileReportHooks";
import { urls } from "../../services/UrlList";
import PerformanceCard from "./PerformanceCard";

const PerformanceDashboard = (props) => {
  const { isLoading: membersLoading, data: teamMembers } =
    useGetProfileReportMembers();

  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(48);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
    }
  };

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  const toggleFeedbackFormTrigger = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody performance-dashboard">
            <div className="p-6 bg-gray-50 min-h-screen">
              <h1 className="page-title text-left">Performance Dashboard</h1>
              <Row>
                {teamMembers?.data.members.map((member) => (
                  <PerformanceCard
                    key={member.id}
                    member={member}
                    storageUrl={urls.storageUrl}
                    toggleFeedbackFormTrigger={toggleFeedbackFormTrigger}
                  />
                ))}
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PerformanceDashboard;
