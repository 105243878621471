import { useMemo } from 'react';
import moment from "moment";

export const useCurrentMonth  = () => {
  return useMemo(() => {
    const currentDate = moment();
    let resultDate;

    if (currentDate.date() < 3) {
      if (currentDate.month() === 0) {
        resultDate = currentDate.subtract(1, 'year').month(11);
      } else {
        resultDate = currentDate.subtract(1, 'month');
      }
    } else {
      resultDate = currentDate;
    }
    return resultDate.format("YYYY-MM");
  }, []);
};

export const useCurrentMonthWithFullName = () => {
  return useMemo(() => {
    const currentDate = moment();
    let resultDate;

    if (currentDate.date() < 3) {
      if (currentDate.month() === 0) {
        resultDate = currentDate.subtract(1, 'year').month(11);
      } else {
        resultDate = currentDate.subtract(1, 'month');
      }
    } else {
      resultDate = currentDate;
    }

    return resultDate.format('YYYY - MMMM'); // "2024 - September"
  }, []);
};

