import React, { useState, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AiTestTracker.css';

const AiTestComments = ({ value, onChange }) => {
  const [comment, setComment] = useState(value || '');

  useEffect(() => {
    if (value !== comment) {
      setComment(value || '');
    }
  }, [value]);

  const onEditorChange = useCallback((newValue) => {
    setComment(newValue);
    onChange(newValue);
  }, [onChange]);

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }],
        ['clean']
      ],
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: true
      }
    }
  };

  useEffect(() => {
    return () => {
      setComment('');
    };
  }, []);

  return (
    <div className="quill-container ai-comments d-flex flex-column">
      <ReactQuill 
        value={comment}
        onChange={onEditorChange}
        placeholder="Enter comments here..." 
        modules={modules}
        theme="snow"
        preserveWhitespace={false}
        bounds=".quill-container"
        maxLength={10000}
      />
    
    </div>
  );
};

export default React.memo(AiTestComments);