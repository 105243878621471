import React, { useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import "./SourceManagement.css";
import Axios from "axios";
import MaterialIcon from "material-icons-react";
import toast, { Toaster } from 'react-hot-toast';
import {
  Button,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import AddRevisions from "./AddRevisions";
import RevisonList from "./RevisionList";
import RevisionData from "./RevisionData";
import RevisionData360 from "./RevisionData360";
import ComposeMessage from "./ComposeMessage";
import { useGetRevsionApproved, useFindShift} from "./useQueryHooks";
import ComposeMessageHub from "./ComposeMessageHub";
import ComposeMessageHubEdit from "./ComposeMessageHubEdit";
import HubTaskComments from "./HubTaskComments";
import UploadProgress from "./UploadProgress";
import {useMediaUpdated } from "../../../MediaContext";

export default function SourceManagement(props) {
  const [showRevision, setShowRevision] = useState(false);
  const [showRevisionList, setShowRevisionList] = useState(true);
  const [showApprovedData, setShowApprovedData] = useState(false);
  const [revisionView, setRevisionView] = useState(0);
  const [revisionID, setRevisionID] = useState("");
  const [openPublisher, setOpenPublisher] = useState(false);
  const [openHubComposer, setOpenHubComposer] = useState(props.editComment ? true : false);
  const [openHubComposerEdit, setOpenHubComposerEdit] = useState(props.editComment ? true : false);
  const [hubComposerEditId, setHubComposerEditId] = useState(props.editComment ? props.editTaskCommentId : 0);
  const [hubComposerEditComment, setHubComposerEditComment] = useState(props.editComment ?  props.editTaskComment:[]);
  const [openComposer, setOpenComposer] = useState(false);
  const [openHubComments, setOpenHubComments] = useState(false);
  const [newComment360, SetNewComment360] = useState('');
  const [minimisedView, setminimisedView] = useState(false);

  const [cardId360, setCardId360] = useState(props.cardId);
  const [attachingFiles, setAttachingFiles] = useState(props.editComment ? props.commentattachments : []);
  const [attachingFoldersOutput, setAttachingFoldersOutput] = useState((props.editComment && props.sourceFiles)  ? props.sourceFiles.output_folders : []);
  const [attachingFoldersSource, setAttachingFoldersSource] = useState((props.editComment && props.sourceFiles) ? props.sourceFiles.source_folders : []);
  const [attachingFoldersOthers, setAttachingFoldersOthers] = useState((props.editComment && props.sourceFiles)  ? props.sourceFiles.other_folders : []);
  const [task360Data, setTask360Data] = useState(props.taskDetails.task_360_id!=null ? JSON.parse(props.taskDetails.task_360_data):'')

  const [taskWhilelabel, setTaskWhitelabel] = useState(props.taskDetails.white_label? props.taskDetails.white_label :0);
  
  const [testCreatePortal, setTestCreatePortal] = useState(false);
  const [uploadingFilesNow, setUploadingFilesNow] = useState([]);
  const [uploadingActive, setUploadingActive] = useState(false);
  const [fileUploadProgressing, setFileUploadProgressing] = useState(false);

  const mediaFilesUpdated = useMediaUpdated();
  const closeCard = (status=0) => {
    if(fileUploadProgressing)
    {
        toast("File uplaoding in progess, You can only minimize this window.")
    }else
    {
      props.closecard(status);
    }
  };
  

  const useUpdatefileUploadProgressing =  (value) =>
  {
    //mediaFilesUpdated(Math.random() * 10);
    if(value===0)
    {
      setFileUploadProgressing(false);
    }
  }


  const useUpdatefileUploadCancel = (uniqueid)=>
  {
    let filesprocessing = [...uploadingFilesNow];
    var itemkey = filesprocessing.map(function (item) { return item.uniqueid; }).indexOf(uniqueid); // (2D) finding the matching key providing value
    filesprocessing[itemkey].cancelstatus=1;

    // setUploadingFilesNow((prevItems) => prevItems.filter((item) => item.uniqueid != uniqueid));
    // setTimeout(() => {
    //   console.log(uploadingFilesNow);
    // }, 2000);
    setUploadingFilesNow(filesprocessing);
  }


  useEffect(()=>
  {
    autoSavedComment();
  },[])

  useEffect(()=>
  {
    props.minimisedview ? setminimisedView(true) : setminimisedView(false);
  },[props.minimisedview])

  const autoSavedComment = () => {
    if (localStorage.getItem("commentData") !== null) {
      var commentData = JSON.parse(localStorage.getItem("commentData"));
      if(commentData.taskID && commentData.taskID === props.tid_cpt && !props.editComment)
      {
        setAttachingFiles(commentData.cattachments ? commentData.cattachments : []);
      }
    }
  }

  const setRevisionNum = (folder) => {
    setRevisionView(folder.revision);
    setRevisionID(folder.id);
    setShowRevision(true);
    setShowRevisionList(false);
  };

  const setApprovedView = () => {
    setShowApprovedData(true);
  };

  const hideApprovedView = () => {
    setShowApprovedData(false);
  };

  const {
    isLoading: approvedLoading,
    data: approvedData,
  } = useGetRevsionApproved(props.taskid);

  const {
    isLoading: shiftLoading,
    data: shiftData,
  } = useFindShift();

  const attachingFilesUpdate = (files) =>
  {
    setAttachingFiles(files);
  }

  const attachingFoldersUpdate = (output, source, others) =>
  {
    setAttachingFoldersOutput(output);
    setAttachingFoldersSource(source);
    setAttachingFoldersOthers(others);
  }


  const addHubCommentAndClose = () =>
  {
    props.sourcecommentpostclose();
    closeCard(1);

  }

  const resetAttachingFiles =()=>
  {
    setAttachingFiles([]);
  }

  const resetAttachingFolders =()=>
  {
    //setAttachingFolders([]);
  }

  const copyContenteToComposer = (comment)=>
  {
    let commentfields = JSON.parse(comment.comment_fields);
    if(commentfields.pmnote && commentfields.pmnote.length>5)
      SetNewComment360(comment.comment+"<br>"+((commentfields.pmnote && commentfields.pmnote!=null) &&  commentfields.pmnote) );
    else
      SetNewComment360(comment.comment);
    setAttachingFiles(JSON.parse(comment.attachments));

    let sourceFiles = comment.source_files !=null ? JSON.parse(comment.source_files) : [];
    setAttachingFoldersOutput(sourceFiles.output_folders);
    setAttachingFoldersSource(sourceFiles.source_folders);
    setAttachingFoldersOthers(sourceFiles.other_folders);

  }

  // const settestCreatePortal = (progresscount) =>
  // {
  //   setTestCreatePortal(false);
  //   if(progresscount.length>0)
  //     {
  //     setTestCreatePortal(true);
  //     setUploadingFiles(progresscount);
  //     }
  //   else
  //   {
  //     setUploadingFiles([]);
  //     console.log("ended");
  //     setTimeout(() => {
  //       setTestCreatePortal(false);
  //     },2000);
     
      
  //   }
  // }

  const minimiseSourceView = ()=>
  {
      props.mimimisedupdate();
  }

  function makeId(length) {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const trigerMediauploadCommand = (media, data) =>
  {
    setUploadingActive(false);
    setFileUploadProgressing(true);
    setTimeout(() => {
      let uploadingitems = uploadingFilesNow;   
      
      for (let i = 0; i < media.length; i++) {
        media[i].folder_path = data.folder_path;
        media[i].revision_id = data.revision_id;
        media[i].folder_id = data.folder_id;
        media[i].task_id = data.task_id;
        media[i].uploadedpercentage = 0;
        media[i].cancelstatus = 0;
        media[i].uniqueid = makeId(5);
        uploadingitems.unshift(media[i]);
      }
      
      setUploadingFilesNow(uploadingitems);
      setUploadingActive(true);
    },500);
     
      //  data.append('folder_path', props.folderpath);
      //  data.append('revision_id', props.revisionid);
      //  data.append('folder_id', props.folderid);
      //  data.append('task_id', props.taskid);
  }

  return (
    <Container id="card-source-management" className={`card-source-management ${minimisedView && 'source-view-minimised'}   ${(openPublisher || openComposer || openHubComposer) && 'publisher-active'}`}>
        <Toaster position="top-right" containerClassName="notifier"
            toastOptions={{
              className: '',
              duration: 3000,
              style: {
                background: '#363636',
                color: '#fff',
              },}}
        />
      <div
        className="trello-card source-management-wrap"
        style={{ marginTop: props.cardtop }}
      > 
        {((openPublisher ||  openHubComposer || openComposer) && !openHubComposerEdit)?
          (
            <div className="kfm_popup_close_action">
            <Button className="sfm-close-icon" onClick={()=>{setShowRevisionList(true); setShowRevision(false); setShowApprovedData(false); resetAttachingFiles(); resetAttachingFolders(); setOpenPublisher(false); setOpenComposer(false); setOpenHubComposer(false); }} >
                <MaterialIcon icon="close" />
            </Button>
            <Button className="sfm-mini-icon" id={"mimimise-source"} onClick={minimiseSourceView}>
              <MaterialIcon icon="remove"/>
            </Button>
            <UncontrolledTooltip  placement="bottom"  target={"mimimise-source"} style={{maxWidth:'600px',width:'auto'}} >Minimize File Management</UncontrolledTooltip >
            </div>
          )
          :
          (
            <div className="kfm_popup_close_action">
            <Button className="sfm-close-icon"  onClick={closeCard} >
              <MaterialIcon icon="close" />
            </Button>
            <Button className="sfm-mini-icon" id={"mimimise-source1"} onClick={minimiseSourceView}>
              <MaterialIcon icon="remove"/>
            </Button>
            <UncontrolledTooltip  placement="bottom"  target={"mimimise-source1"} style={{maxWidth:'600px',width:'auto'}} >Minimize File Management</UncontrolledTooltip >
           </div>
          )}
        <div className="outer-wrapp hub-source">
         
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
            <h5>KimpHub File Management</h5>
            <div className="d-flex sub-file-route">
              <span className="source-board-name">{props.boardname}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{props.cardname}</span>
            </div>
            </div>
           
            <div className="showcmd-btn-wrapper">
          {(!openPublisher && !showApprovedData && !openComposer && !openHubComposer) &&
          (
            <div className="card-info d-flex justify-content-between align-items-start">
              {(showRevisionList && !shiftLoading && shiftData?.data.status==1 )  && <AddRevisions taskid={props.taskid} />}
            </div>
          )}

          {(openComposer || openHubComposer) &&
            (
              <div className="card-info d-flex justify-content-between align-items-start show_cmd_btn mt-0"
               style={{
                 marginRight:0,
               }}
               >
                {openHubComments?
                (<span onClick={(e)=>setOpenHubComments(false)}>Hide Task Comments</span>)
                :
                (<span onClick={(e)=>setOpenHubComments(true)}>Show Task Comments</span>)}
                
              </div>
            )}
          </div>
           
            
          </div>
         
          {((openComposer || openHubComposer) && openHubComments) &&
            (
              <div className="card-info d-flex justify-content-between align-items-center">
                  <HubTaskComments 
                  taskcomments={props.taskcomments} 
                  mytimezone={props.mytimezone} 
                  openComposer={openComposer}
                  copycontenteditor={copyContenteToComposer}
                  />
              </div>
            )}

          {(showApprovedData && !openPublisher) &&
          (
            <div className="card-info d-flex justify-content-between align-items-center">
              <Button className="task_status_change" onClick={hideApprovedView}>Back to Versions</Button>
              {/* <div className="download_all_text_btn">Download All <MaterialIcon icon="file_download"/></div> */}
            </div>
          )}

          {(showRevisionList || showApprovedData ) && (
            <RevisonList
              taskid={props.taskid}
              setRevisionNum={setRevisionNum}
              setApprovedView={setApprovedView}
              approveddata={showApprovedData}
              composerstatus={openComposer}
              publisherstatus={openPublisher}
              canseeapproved={(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com') || props.myPrivileges.includes('i_am_operations')) ? true : false}
              taskcomments={props.taskcomments}
              mytimezone={props.mytimezone}
            />
          )}
          {/* {fileUploadProgressing && 
          (
            useMediaUpdated
          )} */}
          {(showRevision) && (
            <RevisionData
              boardType={props.boardtype}
              taskid={props.taskid}
              revisionView={revisionView}
              revisionID={revisionID}
              showRevision={showRevision}
              parentfolder={0}
              setShowRevision={setShowRevision}
              setShowRevisionList={setShowRevisionList}
              publisherstatus={openPublisher}
              composerstatus={openComposer || openHubComposer ? true : false}
              composerhubstatus={openHubComposer ? true : false}
              attachingFiles={attachingFiles}
              attachingFoldersOutput={attachingFoldersOutput}
              attachingFoldersSource={attachingFoldersSource}
              attachingFoldersOthers={attachingFoldersOthers}
              updateattachfiles={attachingFilesUpdate}
              updateattachfolders={attachingFoldersUpdate}
              taskcomments={props.taskcomments}
              mytimezone={props.mytimezone}
              //setTestCreatePortal={settestCreatePortal}
              myname={props.myname}
              myPrivileges={props.myPrivileges}
              tid_cpt={props.tid_cpt}
              trigermediauploadcommand={trigerMediauploadCommand}
              uploadchange={fileUploadProgressing}
              taskWhilelabel = {taskWhilelabel}
              boardid={props.taskDetails.board_id} 
            />
          )}
        </div>
        {openPublisher &&
        (
          <div className="outer-wrapp source-360">
          <div className="d-flex flex-column">
            <h5>Publish to 360</h5>
            <div className="d-flex sub-file-route">
              <span className="compose-project-data"><strong>KimpHub:</strong></span>
              <span className="source-board-name">{props.boardname}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{props.cardname}</span>
            </div>
            <div className="d-flex sub-file-route">
              <span className="compose-project-data"><strong>Kimp360:</strong></span>
              <span className="source-board-name">{task360Data.project_name}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{task360Data.task_name}</span>
            </div>
          </div>
          <RevisionData360 cardId={props.cardId} taskid={props.taskid} openPublisher={openPublisher} />
        </div>
        )}  

        {openComposer &&
        (
          <div className="outer-wrapp compose-360">
          <div className="d-flex flex-column">
          <div className="compose-message-header">
          <h5>Compose Message</h5>
            <div className="composing-person">
              <span>Composing as </span>
              <h6>{props.myname}</h6>
            </div>
          </div>
            
            <div className="d-flex sub-file-route">
              <span className="compose-project-data"><strong>KimpHub:</strong></span>
              <span className="source-board-name">{props.boardname}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{props.cardname}</span>
            </div>
            <div className="d-flex sub-file-route">
              <span className="compose-project-data"><strong>Kimp360:</strong></span>
              <span className="source-board-name">{task360Data.project_name}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{task360Data.task_name}</span>
            </div>
          </div>
          <ComposeMessage 
            taskid={props.taskid}  
            attachingFiles ={attachingFiles}
            updateattachfiles={attachingFilesUpdate}
            cardId={props.cardId}
            kim360id={props.kim360id}
            attachingFoldersOutput={attachingFoldersOutput}
            attachingFoldersSource={attachingFoldersSource}
            attachingFoldersOthers={attachingFoldersOthers}
            updateattachfolders={attachingFoldersUpdate}
            newcomment360={newComment360}
            tid_cpt = {props.tid_cpt} 
          />
        </div>
        )}  


        {openHubComposer &&
        (
          <div className="outer-wrapp compose-360">
          <div className="d-flex flex-column">
          <div className="compose-message-header">
          <h5>Compose Message</h5>
          </div>
            
            <div className="d-flex sub-file-route">
              <span className="compose-project-data"><strong>KimpHub:</strong></span>
              <span className="source-board-name">{props.boardname}</span>
              <span>
                <MaterialIcon icon="chevron_right" />
              </span>
              <span className="source-card-name">{props.cardname}</span>
            </div>
           
          </div>
          {openHubComposerEdit ? 
          (
            <ComposeMessageHubEdit 
              taskid={props.taskid}  
              editcommentid={props.editTaskCommentId}
              commentfields={props.commentfields}
              comment = {props.editTaskComment}
              attachingFiles ={attachingFiles}
              attachingFoldersOutput={attachingFoldersOutput}
              attachingFoldersSource={attachingFoldersSource}
              attachingFoldersOthers={attachingFoldersOthers}
              updateattachfolders={attachingFoldersUpdate}
              updateattachfiles={attachingFilesUpdate}
              cardId={props.cardId}
              kim360id={props.kim360id}
              availableAssignees={props.availableAssignees}
              taskDetails={props.taskDetails}
              myid={props.myid}
              myname={props.myname}
              assignees = {props.assignees}
              notifiers = {props.notifiers}
              boardname={props.boardname}
              project_id = {props.project_id}
              tid_cpt = {props.tid_cpt} 
              stringifiedDescription={props.stringifiedDescription}
              addHubCommentAndClose={addHubCommentAndClose}
              atValues = {props.atValues}
              />
          )
          :
          (
            <ComposeMessageHub taskid={props.taskid}  
              attachingFiles ={attachingFiles}
              attachingFoldersOutput={attachingFoldersOutput}
              attachingFoldersSource={attachingFoldersSource}
              attachingFoldersOthers={attachingFoldersOthers}
              updateattachfolders={attachingFoldersUpdate}
              updateattachfiles={attachingFilesUpdate}
              cardId={props.cardId}
              kim360id={props.kim360id}
              availableAssignees={props.availableAssignees}
              taskDetails={props.taskDetails}
              myid={props.myid}
              myname={props.myname}
              assignees = {props.assignees}
              notifiers = {props.notifiers}
              boardname={props.boardname}
              project_id = {props.project_id}
              tid_cpt = {props.tid_cpt} 
              stringifiedDescription={props.stringifiedDescription}
              addHubCommentAndClose={addHubCommentAndClose}
              atValues = {props.atValues}
          />
          )}
        </div>
        )} 

        {!openHubComposerEdit &&
        (
          <>
              {(!openPublisher && !openComposer && !openHubComposer)  ? 
              (
                <div className="publish-bottom-sticky publish-compose">
                  <span onClick={()=>{resetAttachingFiles(); resetAttachingFolders(); setOpenPublisher(false); setOpenComposer(false); setOpenHubComposer(true); } } >Compose Hub Message</span>
                  {props.cardId!=null &&
                  (
                  <>
                      {(props.myPrivileges.includes('i_am_pl') || props.myPrivileges.includes('i_am_pm') || props.myPrivileges.includes('i_am_admin') || props.myPrivileges.includes('i_am_com')) &&
                      (
                        <>
                          {(approvedData?.data.success == 1)  &&
                          (
                            <>
                              <span onClick={()=>{ setShowRevisionList(true); setShowRevision(false); setShowApprovedData(true); resetAttachingFiles(); resetAttachingFolders(); setOpenPublisher(true); setOpenComposer(false); setOpenHubComposer(false); } } >Publish to 360</span>
                            </>
                          )}
                          {(props.kim360id && props.kim360id>0) &&
                          (
                            <>
                              <span onClick={()=>{setShowRevisionList(true); setShowRevision(false); setShowApprovedData(false); resetAttachingFiles(); setOpenPublisher(false); setOpenComposer(true); setOpenHubComposer(false); } } >Compose 360 Message</span>
                              </>
                          )}
                        </>
                      )}
                      </>
                  )}
                  </div>
              ):
              (
                <div className="publish-bottom-sticky" onClick={()=>{setShowRevisionList(true); setShowRevision(false); setShowApprovedData(false); resetAttachingFiles(); resetAttachingFolders(); setOpenPublisher(false); setOpenComposer(false); setOpenHubComposer(false); } } >
                  <span >Back to File Manager</span>
                </div>
              )}
          </>
        )}
        
          {uploadingActive &&(
            createPortal(
              <UploadProgress
                uploadingFiles={10}
                filesuploading={uploadingFilesNow}
                fileUploadProgressing={useUpdatefileUploadProgressing}
                fileUploadCancel={useUpdatefileUploadCancel}
              />,
              document.body
            )
          )}

      </div>
    </Container>
  );
}
