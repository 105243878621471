import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const getAiTestMembers = async(month, selectedMember) => {
   return await axios.post(urls.getAiTestMembers, {'month':month, 'selected_member': selectedMember});
}

const addAiTestReport = async(data) =>
    {
        return await axios.post(urls.addAiTestReport, data);
    }

export const useGetAiTestMembers = (month, selectedMember)=>{
    return useQuery(['ai-test-data', month, selectedMember], () => getAiTestMembers(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false, 
    });
}


export const useAddAiTestReport = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addAiTestReport, {
        onSuccess: (response) => {
            if(response.data.success === 10)
            {
                toast('Sorry you are not authorized to do this action.');
            }else
            {
            queryClient.invalidateQueries(['ai-test-data', data.test_month]);
            }
        }
    });
}
