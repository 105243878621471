import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import UnblockShiftCard from "./UnblockShiftCard/UnblockShiftCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";
import { urls } from "../../../services/UrlList";

export default class UnblockShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      isMobileView: 0,
      shiftRemovalRequest: [],
      requestPending: 0,
      progressBar: true,
    };
  }

  componentDidMount = () => {
    this.checkUserPrivilege();
    this.getShiftRemovalRequest();
    const _this = this;
    this.interval = setInterval(() => _this.getShiftRemovalRequest(), 60000);
  };

  componentWillMount = () => {
    this.getShiftRemovalRequest();
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    this.setState({ myPrivileges: login.privileges, myId: login.uid });
    if (!login.privileges.includes("approve_user_requests")) {
      this.props.history.push("/tick-report");
    }
  };

  getShiftRemovalRequest() {
    fetch(urls.shiftRemovalRequest, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          shiftRemovalRequest: response[0].request_list,
          progressBar: false,
        });
        const _this = this;
        setTimeout(function () {
          _this.countPendingRequest(response[0].request_list);
          _this.props.onshiftfuncall(_this.state.requestPending);
        }, 100);
      })
      .catch((error) => {
        this.setState({ progressBar: false });
        const _this = this;
        setTimeout(function () {
          _this.countPendingRequest(_this.state.shiftRemovalRequest);
          _this.props.onshiftfuncall(_this.state.requestPending);
        }, 100);
      });
  }

  countPendingRequest = (response) => {
    let temp = response;
    var count = 0;
    temp.forEach((p1) => {
      if (p1.status === 1) {
        count++;
      }
    });
    this.setState({ requestPending: count });
  };

  unblockShiftApproveAction = (id) => {
    this.setState({ progressBar: true });
    let opts = {
      user_id: id,
      approver_id: this.state.myId,
    };
    fetch(urls.unblockShiftAction, {
      headers: {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((response) => response.json())
      .then((response) => {
        this.getShiftRemovalRequest();
        this.setState({ progressBar: false });
      })
      .catch((error) => {
        this.setState({ progressBar: false });
      });
  };

  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Fragment>
        <ApprovalModules
          classname="appr-pending"
          id="shift-unblock-module"
          title="Unblock shift"
        >
          {/*<Slider {...settings}> */}
          {this.state.shiftRemovalRequest.map((item, index) => {
            return (
              <UnblockShiftCard
                approved={item.status === 0 ? "true" : ""}
                key={index}
                shiftitem={item}
                onunblockshift={this.unblockShiftApproveAction}
              />
            );
          })}
          {/*</Slider> */}
        </ApprovalModules>
      </Fragment>
    );
  }
}
