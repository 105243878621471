import React,{useState, useEffect} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { Button, UncontrolledTooltip } from 'reactstrap';
import {useGetPrivateNotes, useAddPrivateNote, useDeletePrivateNote, useUpdatePrivateNote} from './useBoardHooks';
import moment from 'moment';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';

const PrivateNotes = (props) => {
const [note, setNote] = useState('');
const [notesDataEditId, setNotesDataEditId] = useState(0);
const stripslashes = (str) => { 
    if (str && str.length > 0) {
        str = str.replace(/\\'/g, "'");
        str = str.replace(/\\"/g, '"');
        str = str.replace(/\\0/g, "\0");
        str = str.replace(/\\\\/g, "\\");
    }
    return str;
    };

    const addslashes = (str) => {
        if (str && str.length > 0) {
            str = str.replace(/\\/g, '\\\\');
            str = str.replace(/'/g, "\\'");
            str = str.replace(/"/g, '\\"');
            str = str.replace(/\0/g, '\\0');
        }
        return str;
    };
    
    const {data: notesData, isLoading} = useGetPrivateNotes(props.taskID);

    const addUpdateNoteData = () => {
        const data1 = {
            taskID: props.taskID,
            note: note
        };
        addUpdateNote(data1);
        setNote('');
    };
    const { mutate: addUpdateNote, isLoading: addLoading, } = useAddPrivateNote();

    const deleteNoteData = (id) => {
        const data1 = {
            noteID: id,
            taskID: props.taskID
        };
        deleteNote(data1);
    };
    const { mutate: deleteNote, isLoading: deleteLoading, } = useDeletePrivateNote();

    const privateNoteEditOpen = (note) => { 
        setNote(stripslashes(note.task_note));
        setNotesDataEditId(note.id);
    }

    const updatePrivateNoteData = (id) => {
        const data1 = {
            noteEditID: notesDataEditId,
            taskID: props.taskID,
            note: note
        };
        setNote('');
        updatePrivateNote(data1);
        setNotesDataEditId(0);
    };
    const { mutate: updatePrivateNote, isLoading: updateLoading, } = useUpdatePrivateNote();
    
const textareaRef = React.useRef(null);

useEffect(() => {
    if (notesDataEditId > 0 && textareaRef.current) {
        textareaRef.current.focus();
    }
}, [notesDataEditId]);

return (
    <div>
        {(AccessCheckBlock('i_am_pm') || AccessCheckBlock('i_am_pl'))&&(
            <>
                <p>
                    <TextareaAutosize
                        ref={textareaRef}
                        value={note}
                        placeholder={"Add a private note, only visible to pm and above"}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </p>
                <div className={`d-flex issue-buttons`}>
                    <Button
                        className='btn btn-green add-issue-report'
                        type="button"
                        name="submit"
                        value="Submit"
                        onClick={notesDataEditId > 0 ? updatePrivateNoteData : addUpdateNoteData}
                    >
                        {notesDataEditId > 0 ? 'Update' : 'Save'}
                    </Button>
                </div>
            </>
         )}
        <LoadingWrapper loading={addLoading || deleteLoading || updateLoading}>
            {notesData?.data.notes.map((note, index) => (
                <div className='privite-note-item' key={index}>
                    <p>{stripslashes(note.task_note)}</p>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex note-actions'>
                            {note.added_by === props.myID && (
                                <>
                                    <span onClick={(e) => privateNoteEditOpen(note)}>Edit</span>
                                    <span> | </span>
                                    <span onClick={(e) => deleteNoteData(note.id)}>Delete</span>
                                </>
                            )}
                        </div>
                        <div className='d-flex justify-content-end note-item'>
                            <span>{note.full_name}</span>
                            <span> | </span>
                            <span id={`note-${index}`}>{moment(note.added_on).format('MMMM D')}</span>
                            <UncontrolledTooltip placement="top" target={`note-${index}`}>
                                <span>{moment(note.added_on).format('MMMM D, YYYY h:mm a')}</span>
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </div>
            ))}
        </LoadingWrapper>
    </div>
);
};
export default PrivateNotes;