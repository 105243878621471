import React, { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { AccessCheckBlock } from "../bcmodule/AccessCheck";
import AiTestComments from "./AITestComments";
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import MaterialIcon from "material-icons-react";
import getFileType  from './getFileType';
const CommentsDrawer = ({
  isOpen,
  onClose,
  data,
  currentIndex,
  myPrivileges,
  onNext,
  onPrevious,
  onUpdate,
}) => {
  const currentItem = data[currentIndex];
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState(currentItem);
  React.useEffect(() => {
    if (currentItem) {
      setEditedItem(currentItem);
      setIsEditing(false);
    }
  }, [currentItem]);

  if (!isOpen || !currentItem) return null;

  const handleEdit = () => {
    setIsEditing(true);
  };

//   const handleSave = () => {
//     onUpdate(currentIndex, { ...editedItem, comments: stripslashes(editedItem.comments) });
//     setIsEditing(false);
//   };

const handleSave = () => {
    // Clean up the comments HTML before saving
    const cleanComments = editedItem.comments
      ?.replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>') // Remove double line breaks
      .replace(/^<br>|<br>$/g, ''); // Remove leading/trailing <br>
  
    onUpdate(currentIndex, { 
      ...editedItem, 
      comments: stripslashes(cleanComments || '') 
    });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedItem(currentItem);
    setIsEditing(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (isEditing) {
        if (
          window.confirm(
            "You have unsaved changes. Are you sure you want to close?"
          )
        ) {
          handleCancel();
          onClose();
        }
      } else {
        onClose();
      }
    }
  };

//   const handleChange = (field, value) => {
//     setEditedItem((prev) => ({
//       ...prev,
//       [field]: value,
//     }));
//   };

const handleChange = (field, value) => {
    setEditedItem(prev => ({
      ...prev,
      [field]: typeof value === 'object' ? value.target.value : value
    }));
  };


const handleLinkClick = (type) => {
    const link = currentItem[type];
    if (link !== null) {
        window.open(link, '_blank');
    }
};

const formatDateForDisplay = (date) => {
    return moment(date).format('DD-MMM-YYYY');
  };

  const parseDateString = (dateStr) => {
    return moment(dateStr, 'DD-MMM-YYYY').toDate();
  };


  const hoursOptions = Array.from({ length: 13 }, (_, i) => i);
  const minutesOptions = Array.from({ length: 60 }, (_, i) => i);

  const getStatusClass = (status) => {
    switch(parseInt(status)) {
      case 1:
        return "text-success";
      case 2:
        return "text-danger";
      case 3:
        return "text-warning";
      default:
        return "";
    }
  };

  const getStatusLabel = (status) => {
    switch(parseInt(status)) {
      case 1:
        return "Pass";
      case 2:
        return "Failed";
      case 3:
        return "Average";
      default:
        return "";
    }
  };

  const stripslashes = (str) => {
    if (str && str.length > 0) {
      str = str.replace(/\\'/g, "'");
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, "\0");
      str = str.replace(/\\\\/g, "\\");
    }
    return str;
  };

//   const handleEditorChange = (content) => {
//     setValue(content);
//     if (onUpdate) {
//       onUpdate(content);
//     }
//   };


  const drawerStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    drawer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: "500px",
      backgroundColor: "white",
      boxShadow: "-2px 0 8px rgba(0, 0, 0, 0.15)",
      zIndex: 1001,
    },
    content: {
      height: "100%",
      overflow: "auto",
      padding: "1.5rem",
      flexGrow: 1,
    },
    buttonContainer: {
      // position: "fixed",
      // bottom: 0,
      // left: 0,
      // right: 0,
      backgroundColor: "white",
      padding: "1rem 0",
      borderTop: "1px solid #e5e7eb",
      zIndex: 1002,
    },
  };

  return (
    <div style={drawerStyles.overlay} onClick={handleOverlayClick}>
      <div style={drawerStyles.drawer} onClick={(e) => e.stopPropagation()}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            style={{
              flexShrink: 0,
              padding: "1rem",
              borderBottom: "1px solid #e5e7eb",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h5>Details for {currentItem.name}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div style={{ flexGrow: 1, overflowY: "auto", padding: "1rem" }}>
            <div>
              <div className="mb-3">
                <h6>Test Link</h6>
                {isEditing ? (
                  <input
                    type="text"
                    className="form-control"
                    value={editedItem.test_link}
                    onChange={(e) => handleChange("test_link", e.target.value)}
                  />
                ) : (
                  <p>
                    {currentItem.test_link!== null ? 
                    (
                        <a
                            href="#"
                            className="text-primary" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick('test_link');
                            }}
                        >
                            Link
                        </a>
                    ) : '-'}
                  </p>
                )}
              </div>
               <div className="mb-3">
                    <h6>Test Date</h6>
                    {isEditing ? (
                        <Datetime
                        value={parseDateString(editedItem.test_date)}
                        onChange={(date) => {
                          const formattedDate = formatDateForDisplay(date);
                          handleChange("test_date", formattedDate);
                        }}
                        dateFormat="DD-MMM-YYYY"
                        timeFormat={false}
                        className="form-control"
                        inputProps={{
                          placeholder: "Select date"
                        }}
                        // isValidDate={(currentDate) => currentDate >= moment().startOf('day')}
                      />
                    ) : (
                        <p>{currentItem.test_date}</p>
                    )}
              </div>

              <div className="mb-2 d-flex">
                <div className="ai-status-result">
                  <h6>Status</h6>
                  {isEditing ? (
                    <select
                      className="form-select"
                      value={editedItem.status}
                      onChange={(e) => handleChange("status", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="1">Pass</option>
                      <option value="2">Failed</option>
                      <option value="3">Average</option>
                    </select>
                  ) : (
                    <p className={`${currentItem.status >0 && getStatusClass(currentItem.status)}`}>
                      {currentItem.status>0 ? getStatusLabel(currentItem.status) : '-'}
                    </p>
                  )}
                </div>
                <div className="ai-rating-result">
                  <h6>Rating</h6>
                  {isEditing ? (
                    <select
                      className="form-select"
                      value={editedItem.rating}
                      onChange={(e) => handleChange("rating", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                    </select>
                  ) : (
                    <p className=''>
                      {currentItem.rating>0 ? currentItem.rating : '-'}
                    </p>
                  )}
                </div>
                <div className="mb-3">
                <h6>Time Taken</h6>
                {isEditing ? (
                  <div className="d-flex gap-2">
                    <div className="d-flex align-items-center">
                      <select
                        className="form-select"
                        value={editedItem.timeTakenHours}
                        onChange={(e) =>
                          handleChange(
                            "timeTakenHours",
                            parseInt(e.target.value)
                          )
                        }
                      >
                        {hoursOptions.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                      <span className="mx-2">hrs</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <select
                        className="form-select"
                        value={editedItem.timeTakenMinutes}
                        onChange={(e) =>
                          handleChange(
                            "timeTakenMinutes",
                            parseInt(e.target.value)
                          )
                        }
                      >
                        {minutesOptions.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                      <span className="mx-2">mins</span>
                    </div>
                  </div>
                ) : (
                  <p>
                   {currentItem.timeTakenHours >0 && `${currentItem.timeTakenHours>1 ? currentItem.timeTakenHours+" hrs" : currentItem.timeTakenHours+" hr"}`} 
                    {currentItem.timeTakenHours >0 && currentItem.timeTakenMinutes >0 && ' & '}
                    {currentItem.timeTakenMinutes >0 && `${currentItem.timeTakenMinutes} mins`}

                    {currentItem.timeTakenMinutes === null && currentItem.timeTakenHours === null && '-'}

                  </p>
                )}
                </div>
              </div>

              <div className="mb-3">
                <h6>Files Link</h6>
                {isEditing ? (
                  <div className="ai-files-wrap">
                    <input
                      type="text"
                      placeholder="Images zip file link"
                      className="form-control"
                      value={editedItem.files_link}
                      onChange={(e) => handleChange("files_link", e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Video screen recording link"
                      className="form-control"
                      value={editedItem.files_link2}
                      onChange={(e) => handleChange("files_link2", e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Other files if any"
                      className="form-control"
                      value={editedItem.files_link3}
                      onChange={(e) => handleChange("files_link3", e.target.value)}
                    />
                  </div>
                ) : (
                  <p className="test-files">
                    {currentItem.files_link!== null && currentItem.files_link!='' && 
                    (
                        <a
                            href="#"
                            className="text-primary" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick('files_link');
                            }}
                        >
                            <MaterialIcon icon={'folder_zip'}  />
                        </a>
                    )}
                    {currentItem.files_link2!== null && currentItem.files_link2!='' && 
                    (
                        <a
                            href="#"
                            className="text-primary" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick('files_link2');
                            }}
                        >
                            <MaterialIcon icon={'video_file'}   />
                        </a>
                    )}
                    {currentItem.files_link3!== null && currentItem.files_link3!== '' && 
                    (
                        <a
                            href="#"
                            className="text-primary" 
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick('files_link3');
                            }}
                        >
                            <MaterialIcon icon={'attachment'}   />
                        </a>
                    )}
                 </p>
                )}
              </div>

              <div className="mb-3">
                <h6>Comments</h6>
                {isEditing ? (
                //    <textarea
                //    className="form-control"
                //    value={editedItem.comments ? stripslashes(editedItem.comments) : ''}
                //    onChange={(e) => handleChange("comments", e.target.value)}
                //    rows="3"
                //  />

                <AiTestComments 
                    value={editedItem.comments ? stripslashes(editedItem.comments) : ''} 
                    onChange={(value) => handleChange("comments", value)}
                    />
                ) : (
                    <Linkify className="cmd_editer_preview"  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>
                        { ReactHtmlParser( stripslashes(currentItem.comments) +" ")}
                    </Linkify>
                )}
                </div>
            </div>
          </div>
          <div
            style={{
              flexShrink: 0,
              padding: "1rem",
              borderTop: "1px solid #e5e7eb",
            }}
            className="d-flex  gap-2 justify-content-between align-items-center"
          >
            {isEditing ? (
              <>
                <button
                  className="btn btn-secondary cancelbtncd rounded-pill px-4"
                  onClick={handleCancel}
                  style={{ minWidth: "140px" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary saveChangebtncd rounded-pill px-4"
                  onClick={handleSave}
                  style={{
                    backgroundColor: "#0D6EFD",
                    minWidth: "140px",
                  }}
                >
                  Save Changes
                </button>
              </>
            ) : (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between align-items-center"
              >
                {AccessCheckBlock('aitest_manager') && (
                    <button
                    className="btn btn-primary editBtncd rounded-pill px-4"
                    onClick={handleEdit}
                    style={{
                      backgroundColor: "#0D6EFD",
                      minWidth: "120px",
                    }}
                  >
                    Edit
                  </button>
                 )}
                <div
                  className="d-flex gap-1"
                  style={{
                    gap: "10px",
                  }}
                >
                  <button
                    className="btn btn-outline-secondary prevBtncd rounded-pill px-4"
                    onClick={onPrevious}
                    disabled={currentIndex === 0}
                    style={{ minWidth: "120px" }}
                  >
                    Previous
                  </button>

                  <button
                    className="btn btn-outline-secondary nextBtncd rounded-pill px-4"
                    onClick={onNext}
                    disabled={currentIndex === data.length - 1}
                    style={{ minWidth: "120px" }}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div style={drawe</div>rStyles.content}>
          <div className="d-flex justify-content-between align-items-center mb-4">
           
          </div>
          <div className="mb-4">
            <h6>Test Link</h6>
            {isEditing ? (
              <input
                type="text"
                className="form-control"
                value={editedItem.testLink}
                onChange={(e) => handleChange("testLink", e.target.value)}
              />
            ) : (
              <p>{currentItem.testLink}</p>
            )}
          </div>

          <div className="mb-4">
            <h6>Test Date</h6>
            {isEditing ? (
              <input
                type="date"
                className="form-control"
                value={formatDateForInput(editedItem.testDate)}
                onChange={(e) => {
                  const formattedDate = formatDateForDisplay(e.target.value);
                  handleChange("testDate", formattedDate);
                }}
              />
            ) : (
              <p>{currentItem.testDate}</p>
            )}
          </div>

          <div className="mb-4">
            <h6>Time Taken</h6>
            {isEditing ? (
              <div className="d-flex gap-2">
                <div className="d-flex align-items-center">
                  <select
                    className="form-select"
                    value={editedItem.timeTakenHours}
                    onChange={(e) =>
                      handleChange("timeTakenHours", parseInt(e.target.value))
                    }
                  >
                    {hoursOptions.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                  <span className="mx-2">hrs</span>
                </div>
                <div className="d-flex align-items-center">
                  <select
                    className="form-select"
                    value={editedItem.timeTakenMinutes}
                    onChange={(e) =>
                      handleChange("timeTakenMinutes", parseInt(e.target.value))
                    }
                  >
                    {minutesOptions.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <span className="mx-2">mins</span>
                </div>
              </div>
            ) : (
              <p>
                {currentItem.timeTakenHours > 0 &&
                  `${currentItem.timeTakenHours} hr`}
                {currentItem.timeTakenMinutes > 0 &&
                  ` ${currentItem.timeTakenMinutes} mins`}
              </p>
            )}
          </div>

          <div className="mb-4">
            <h6>Files Link</h6>
            {isEditing ? (
              <input
                type="text"
                className="form-control"
                value={editedItem.filesLink}
                onChange={(e) => handleChange("filesLink", e.target.value)}
              />
            ) : (
              <p>{currentItem.filesLink}</p>
            )}
          </div>

          <div className="mb-4">
            <h6>Status</h6>
            {isEditing ? (
              <select
                className="form-select"
                value={editedItem.status}
                onChange={(e) => handleChange("status", e.target.value)}
              >
                <option value="Pass">Pass</option>
                <option value="Fail">Fail</option>
                <option value="Need to be reconsidered">
                  Need to be reconsidered
                </option>
              </select>
            ) : (
              <p className={`${getStatusClass(currentItem.status)}`}>
                {currentItem.status}
              </p>
            )}
          </div>

          <div className="mb-4">
            <h6>Comments</h6>
            {isEditing ? (
              <textarea
                className="form-control"
                value={editedItem.comments}
                onChange={(e) => handleChange("comments", e.target.value)}
                rows="3"
              />
            ) : (
              <p>{currentItem.comments}</p>
            )}
          </div>
        </div>

        <div style={drawerStyles.buttonContainer}>
          <div className="d-flex justify-content-between px-4">
            {isEditing ? (
              <>
                <button
                  className="btn btn-secondary rounded-pill px-4"
                  onClick={handleCancel}
                  style={{ minWidth: "140px" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary rounded-pill px-4"
                  onClick={handleSave}
                  style={{
                    backgroundColor: "#0D6EFD",
                    minWidth: "140px",
                  }}
                >
                  Save Changes
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-outline-secondary rounded-pill px-4"
                  onClick={onPrevious}
                  disabled={currentIndex === 0}
                  style={{ minWidth: "120px" }}
                >
                  Previous
                </button>
                <button
                  className="btn btn-primary rounded-pill px-4"
                  onClick={handleEdit}
                  style={{
                    backgroundColor: "#0D6EFD",
                    minWidth: "120px",
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-outline-secondary rounded-pill px-4"
                  onClick={onNext}
                  disabled={currentIndex === data.length - 1}
                  style={{ minWidth: "120px" }}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CommentsDrawer;
