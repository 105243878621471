import React from 'react';

const MonthSelector = ({ month, onChange }) => {
  const currentDate = new Date();
  const getMonthLabel = (monthsAgo) => {
    const date = new Date();
    date.setMonth(currentDate.getMonth() - monthsAgo);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  };
  
  const options = Array.from({ length: 3 }, (_, i) => ({
    value: i,
    label: getMonthLabel(i)
  }));

  return (
    <select
      className="graph-period head-filter-month monthsort_select"
      value={month}
      onChange={(e) => onChange(parseInt(e.target.value))}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};
export default MonthSelector;