import React, { Component, Fragment } from 'react';
import './login.css';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Input, 
  InputGroup, 
  Label, 
  Button,
  CardBody,
  CardTitle,
  CardText, 
  FormGroup,
  Spinner,
  Alert} from 'reactstrap';

import {urls} from "../../services/UrlList";
import "../../components/common/Routes";
import {motion} from 'framer-motion'
import MonkeyImg from '../../images/cycle_with_monky.png'
import KimpLogo from "../../images/Kimp_whitelogo.svg";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";

export default class Login extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      expireTime: new Date().getTime()+(18*60*60*1000),
      email: "",
      password: "",
      emailValid: false,
      passwordValid: false,
      formErrors: {
        email: "",
        password: "" 
      },
      formValid: false,
      data: null,
      showAlert: false,
      progressBar: false,
      message:
        "Username or password is not correct. Please try again!"
    };
  }

  componentDidMount =() => {
    document.title = "KIMP - Login";
    
  };

 

  login = (e) => {
    e.preventDefault();
    this.setState({
      showAlert: false,
      progressBar: true
    });
    let opts1 = {
      username: this.state.email,
      password: this.state.password
    };
    let opts={};
    fetch(urls.logmein, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts1)
    })
      .then(response => response.json())
      .then(response => {
        const lastVisitedPage = localStorage.getItem('lastVisitedPage');
        localStorage.clear();
        if (response && response.length > 0) {
          if (response[0][0]["status"] === 1) {
            localStorage.setItem("khubexpire", this.state.expireTime);
            opts.loggedIn = true;
            opts.uid = response[0][0]["uid"];
            opts.kimp360id = response[0][0]["kimp360_id"];
            opts.name = response[0][0]["name"];
            opts.user_type = response[0][0]["user_type"];
            opts.employee_type = response[0][0]["employee_type"];
            opts.privileges = response[0][0]['privileges'];
            opts.showext = response[0][0]['extended_break_access'];
            opts.usershift = response[0][0]['user_shift'];
            opts.basecampid = response[0][0]['basecamp_uid'];
            opts.user_team = response[0][0]['user_teamid'];
            opts.my_timezone = response[0][0]['my_timezone'];
            opts.apptoken = response[0][0]['apptoken'];
            opts.trackingenabled = response[0][0]['tracking_enabled'];
            localStorage.setItem("user", JSON.stringify(opts));
            localStorage.setItem("firstlogin", 1);
            if(response[0][0]['privileges'].includes('allow_user_switching'))
            {
              localStorage.setItem("superadmin", response[0][0]["uid"]);
            }
            const _this = this;
            setTimeout(function() {
              _this.setState({
                progressBar: false,
                showAlert: false
              });
              if (lastVisitedPage) {
                _this.props.history.push(lastVisitedPage);
                localStorage.removeItem('lastVisitedPage'); 
              } else {
                _this.props.history.push("/home");
              }
            }, 2000);  
                               
          } else {
            this.setState({
              progressBar: false,
              message: "Invalid Credentials",
              showAlert: true
            });
          }
        } else {
          this.setState({
            progressBar: false,
            showAlert: true
          });
        }
      })
      .catch(error => {
        this.setState({
          progressBar: false,
          showAlert: true
        });
      });
  };

  // resetPassword = () => {
  //   this.props.history.push("/forgotpassword");
  // };

  handleUserInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value
      });
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? "" : " is too short";
        break;
      default:
        break;
    }
    this.setState(
      {
        emailValid: emailValid,
        passwordValid: passwordValid
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid: this.state.emailValid && this.state.passwordValid
    });
  };

  onDismiss = () => {
    this.setState({ showAlert: false });
  };

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }

    return (

   
    
     

      <Fragment>


<section className="login_page">
  <Container fluid>
    <Row>
      <Col xs="12" lg="6" className="pad_LR_0">
        <div className="monkey_img_sec"></div>
        {/* <img src={MonkeyImg} alt="Monkey Image"/> */}
      </Col>
    <Col xs="12" lg="6" className="white_bg">
      {/* <div className="login_logo"><img src={KimpLogo}/></div> */}
         <div className="login_from">
          <motion.div initial="hidden" animate="visible" variants={variants}>
            <CardTitle tag="h2">Welcome to the<br/>Kimp Hub!</CardTitle>
            <CardText>Please login to your account</CardText>
          
            <Alert
                        color="danger"
                        isOpen={this.state.showAlert}
                        toggle={this.onDismiss.bind(this)}
                        fade={false}
                      >
                      {this.state.message}
                      </Alert>
            <form className="signin_from">
             
                <FormGroup>
                <LoadingWrapper  className="flex_direction" isLoading={this.state.progressBar}> 
                  <input
                    
                    id="exampleEmail"
                    className="custom_input_style"
                    type="email" 
                    placeholder="Email Address"
                    name="email"
                    InputProps={{readOnly: false,}} InputLabelProps={{ shrink: true, }}  onChange={event => this.handleUserInput(event)}

                  />

                  <Label className="form-label" for="exampleEmail"> Email</Label>
              </LoadingWrapper>
                </FormGroup>
                {this.state.formErrors.email ? (
                            <p className="login-error error-email">
                              * Email {this.state.formErrors.email}
                            </p>
                          ) : null}
              
                <FormGroup >
                <LoadingWrapper className="flex_direction" isLoading={this.state.progressBar}> 
                  <input
                    placeholder="Password"
                    id="examplePassword"
                    className="custom_input_style"
                    name="password"
                    type="password"
                    InputLabelProps={{ shrink: true,}}  onChange={event => this.handleUserInput(event)}
                  />
                  <Label className="form-label" for="examplePassword">Password </Label>
              </LoadingWrapper>
                </FormGroup>
                {this.state.formErrors.password ? (
                          <p className="login-error error-pass">
                            * Password {this.state.formErrors.password}
                          </p>
                        ) : null}


                    <Button variant="contained" id="form-submit" className="login_btn" type="submit" value="Login" 
                        onClick={this.login} onKeyPress={this.login}> login
                       
                        </Button>
</form>
              </motion.div>   
          </div>
      </Col> 
    </Row>
  </Container>
</section>
</Fragment>
)
  }
}

