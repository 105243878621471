import React, { Component, Fragment } from "react";
import "./LeaveApply.css";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import {Container,Row,Col,Button,Form,FormGroup,Label,Input,FormText,Card,CardTitle} from "reactstrap";
import LeaveHeader from "../LeaveHeader/LeaveHeader";
import AttachmentIcon from "../../../images/folder_file.svg";
import PlusIcon from "../../svgicon/PlusIcon";
import CloseIcon from "../../svgicon/CloseIcon";
import closeIconImg from "../../../images/close-icon.png";
import CalenderIcon from "../../../images/date-icon.png";
import DateTimePicker from "react-datetime-picker";
import CcPersonSingle from "./CcPersonSingle/CcPersonSingle";
import {urls} from "../../../services/UrlList";
import LoadingBar from "../../common/LoadingBar";
import Alertlogmsg from "../../messages/Alertlogmsg";
import { isMobile } from "react-device-detect";
import LeaveCalenderDetails from "../LeaveCalender/LeaveCalender"
import FileUpload from "../../common/FileUpload";
import FileViewer from "./FileViewer";

export default class LeaveApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      progressBar:true,
      myPrivileges: [],
      myId: "",
      leaveTypes:[],
      selectedLeaveType:'',
      leaveBalance:0,
      applyingFor:0,
      myPhoneNumber:'',
      myLeaveReason:'',
      allUsers:[],
      allUsersOriginal:[],
      allReporters:[],
      showCcPeople:false,
      nameFilter:'',
      selectedReporter:'',
      selectedPeoples:[],
      holidays:[],
      selectedHolidayId:'',
      selectedHolidayDate:'',
      sellectedType:'',
      applyingOverQuota:false,
      sessionChoosen:3,
      sessionDateerror:false,
      sessionSelectionMsg:'Please correct your entry. It looks like you are applying leave for more than one day, but you choosen a wrong session type.',
      showOverQuota:false,
      showOverQuotaMsg: 'Sorry, you don\'t have enough leave balance in this leave type to apply for leave', 
      showApplyResposeSuccess:false,  
      showApplyResposeError:false,    
      showApplyResposeMsgSuccess:'Leave application posted successfully',
      showApplyResposeMsgError:'Something went wrong, please check your entries and try again',
      leaveTypeError:false,
      leavePhoneError:false,
      leaveToError:false,
      leaveCCError:false,
      leaveReasonError:false,
      showMenu:true,
      employeeType:'',
      username:'',
      leaveFiles:[],
      leaveFilesChange:false,
      maxDate: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)
    };
  }

  

  componentDidMount =() =>
  {
    this.checkUserPrivilege();
    this.renderContentDevice();      
    
    const _this = this;
    setTimeout(function() {  
      _this.getLeaveTypes();   
      _this.getMyUpcomingHolidays();
    }, 100);    
    this.getDotoUsers();
    this.getDotoReporters();
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        employeeType: login.employee_type,     
        username:login.name  
      });
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  componentDidUpdate(prevState) {
    if (prevState.sessionDateerror !== this.state.sessionDateerror && this.state.sessionDateerror === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ sessionDateerror: false });
      }, 10000);
    }

    if (prevState.showOverQuota !== this.state.showOverQuota && this.state.showOverQuota === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showOverQuota: false });
      }, 10000);
    }

    if (prevState.showApplyResposeSuccess !== this.state.showApplyResposeSuccess && this.state.showApplyResposeSuccess === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showApplyResposeSuccess: false });
        window.location.reload();
      }, 6000);
    }    

    if (prevState.showApplyResposeError !== this.state.showApplyResposeError && this.state.showApplyResposeError === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showApplyResposeError: false });
        window.location.reload();
      }, 6000);
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  getLeaveTypes = () =>
  {
    fetch(urls.getLeaveTypes+this.state.myId,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json())
      .then(response =>
      {      
        console.log(response);
        this.setState({
          leaveTypes:response,         
          progressBar:false,
        })
      })
      .catch((error) =>
      {
        this.setState({        
          progressBar:false,
        })
      })
  }  
  getLeaveTypeBalance = (e) =>
  {
    let ltype = e.target.value;
    if(e.target.value !=9)
    {
      let opts = {
        user_id:this.state.myId,
        leave_type: ltype
      };
      this.setState({selectedLeaveType:ltype, sellectedType:ltype});
      fetch(urls.postLeaveTypeBalance,
        {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {  
          this.calcNumberofDaysApplying();    
          this.setState({
            leaveBalance:response[0].leave_balance, 
            sellectedType:ltype, 
            sessionChoosen:3,   
            progressBar:false,
            applyingFor:1,
            startDate: new Date(),
            endDate: new Date(),
          })
        })
        .catch((error) =>
        {
          this.setState({        
            progressBar:false,
          })
        })
    }else{
      this.calcNumberofDaysApplying(); 
      this.setState({
        leaveBalance:0, 
        sellectedType:ltype,             
      })
    }    
  }


  getMyUpcomingHolidays = () =>
  {    
    fetch(urls.getMyUpcomingHolidays+ this.state.myId,
    {
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
    {            
      this.setState({
       holidays:response,      
      })
    })
    .catch((error) =>
    {
     
    })
  };

  countWeekendDays( d0, d1 )
  {
    var ndays = 1 + Math.round((d1.getTime()-d0.getTime())/(24*3600*1000));
    var nsaturdays = Math.floor( (d0.getDay()+ndays) / 7 );
    return 2*nsaturdays + (d0.getDay()==0) - (d1.getDay()==6);
  }

  calcNumberofDaysApplying = () =>
  {
    const date1 = new Date(this.state.startDate);
    const date2 = new Date(this.state.endDate);
    const diffTime = Math.abs(date2 - date1);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    var weekends = this.countWeekendDays(date1, date2);
    diffDays = diffDays + 1;
    this.setState({applyingFor:(diffDays - weekends) });
    const _this = this;
    setTimeout(function() {     
      _this.calcOverQuotaLeave();
    }, 100);

    if(this.state.sessionChoosen !=3 && (diffDays - weekends)>1)
    {
      this.setState({sessionDateerror:true});
    }else if(this.state.sessionChoosen !=3 && (diffDays - weekends) === 1)
    {
      this.setState({applyingFor:0.5 });
    }    
  }

  calcOverQuotaLeave = () =>
  {   
    if(this.state.sellectedType !=9 && this.state.applyingFor > this.state.leaveBalance)
    {
        this.setState({applyingOverQuota:true});
    }else
    {
        this.setState({applyingOverQuota:false});
    }
  }

  getDotoUsers = () =>
  {
    fetch(urls.getAllDotoUsers,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json())
      .then(response =>
      {      
        this.setState({
          allUsers:response,  
          allUsersOriginal:response,       
          progressBar:false,
        })
      })
      .catch((error) =>
      {
        this.setState({        
          progressBar:false,
        })
      })
  }

  getDotoReporters = () =>
  {
    fetch(urls.getDotoReporters,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json())
      .then(response =>
      {      
        this.setState({
          allReporters:response,               
          progressBar:false,
        })
      })
      .catch((error) =>
      {
        this.setState({        
          progressBar:false,
        })
      })

  }

  getSortedPeople = () =>
  {
    let peoples = this.state.allUsersOriginal;
    let list = [];
    peoples.forEach((p1) =>{
      var pname = p1.name.toLowerCase();
      console.log(pname);
      if(pname.startsWith(this.state.nameFilter.toLowerCase()))
      {
        list.push(p1);
      }
    })
    this.setState({allUsers:list});
  }
  

  showCcPeople = () =>
  {
    this.setState({showCcPeople: !this.state.showCcPeople});
  }

  hideCcPeople = () =>
  {
    this.setState({showCcPeople: !this.state.showCcPeople});
  }
  
  onSdateChange = (date) => 
  {
    this.setState({ startDate: date });
    const _this = this;
    setTimeout(function() {     
      _this.calcNumberofDaysApplying();
    }, 100);
    
  }
  onEdateChange = (date) => {
    this.setState({ endDate: date });
    const _this = this;
    setTimeout(function() {     
      _this.calcNumberofDaysApplying();
    }, 100);
  }

  onSessionChange = (e) =>
  {
    if(e.target.value === 3)
    {
      this.setState({sessionDateerror:false})
    }
    this.setState({sessionChoosen:e.target.value});
    const _this = this;
    setTimeout(function() {     
      _this.calcNumberofDaysApplying();
    }, 100);
  }

  onHolidaySelect = (e) =>
  {
    this.setState({selectedHolidayId:e.target.value});
  };
  onNumberChange = e =>this.setState({myPhoneNumber: e.target.value});
  onReasonChange = e =>this.setState({myLeaveReason:e.target.value});
  onNameFilterChange = (e) =>{
    this.setState({nameFilter:e.target.value});   
    const _this = this;
    setTimeout(function() {     
      _this.getSortedPeople();
    }, 200);
  } 

  onCcSet =(cc) =>
  {  
    this.setState({selectedPeoples:cc});
  }

  onReporterSelect = (e) =>
  {
    this.setState({selectedReporter:e.target.value});
  }

  applyForLeave = () =>
  {
    this.setState({
      leaveTypeError:false,leavePhoneError:false,leaveToError:false,leaveCCError:false,leaveReasonError:false,
      leaveApplyTo:false, leaveApplyFrom:false,
    });
    if(this.state.sellectedType === '')
    {
        this.setState({leaveTypeError:true});
        return false;
    }else if(this.state.myPhoneNumber === '')
    {
        this.setState({leavePhoneError:true});
        return false;
    }else if(this.state.selectedReporter === '')
    {
        this.setState({leaveToError:true});
        return false;
    }else if(this.state.selectedPeoples.length === 0)
    {
        this.setState({leaveCCError:true});
        return false;
    }else if(this.state.myLeaveReason === '')
    {
        this.setState({leaveReasonError:true});
        return false;
    }
   /* else if(this.state.myLeaveReason === '')
    {
        this.setState({leaveReasonError:true});
        return false;
    }
    */
    if(this.state.applyingOverQuota)
    {
      this.setState({showOverQuota:true});
      return;
    }else
    {
      this.setState({        
        progressBar:true,
        });
        window.scrollTo(0, 0);
      let opts={
        user_id:this.state.myId,
        username:this.state.username,
        leave_type:this.state.sellectedType,
        leave_from: this.state.startDate,
        leave_to: this.state.endDate,
        session: this.state.sessionChoosen,
        applying_for: this.state.applyingFor,
        mobile_number: this.state.myPhoneNumber,
        applying_to: this.state.selectedReporter,
        applying_cc:this.state.selectedPeoples,
        reason: this.state.myLeaveReason,
        holidayid:this.state.selectedHolidayId,
        attachments: this.state.leaveFiles
      };  
      fetch(urls.postApplyLeave,
        {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {    
          this.setState({
            showApplyResposeSuccess:response[0]['status'] == 1? true:false,
            showApplyResposeError:response[0]['status'] == 0? true:false,           
            progressBar:false});          
        })
        .catch((error) =>
        {
          this.setState({        
            progressBar:false,
          })
        })

    }
  }

  storeFileNames = async(item)=>
  {
    let temp =[...this.state.leaveFiles];
    this.setState({leaveFilesChange:false});
    await this.setState({leaveFiles:[]});
    temp.push(item);
    await this.setState({leaveFiles: temp});
    this.setState({leaveFilesChange:true});
  }

  deletefiles = (filename, arrayname) =>
  {
    if(arrayname == 'leaves')
    {
      this.setState({leaveFilesChange:false});
      var assetupdate = [...this.state.leaveFiles];
      var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
      assetupdate.splice(itemkey, 1);
      const _this = this;
      setTimeout(()=>
      {
        _this.setState({leaveFiles: assetupdate, leaveFilesChange:true});
      }, 500);
    }
  }

  render() {
    var array = [...this.state.selectedPeoples].map(Number);
    return (
      <div className="fullwidth_mobilemenu">
      
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}  >
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
          logout={this.signOut.bind(this)} 
          onmenuaction={this.toggleSidebar.bind(this)}
          quickactionblock={true} />        
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="leave" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody themefullwidthContentBoday">
          <Row>
            <Col sm={12} lg={8} className="mb-4">
            <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title"> Leave</CardTitle>
                <LeaveHeader active="apply" />
                <div className="leave_body">
                  <h4 className="title text-center"> {this.state.employeeType === '2' ? 'Apply for Leave' : 'Apply for Leave' }</h4>
                 { this.state.progressBar == true ? <div style={{height:'40px'}}><LoadingBar /></div>  : null } 
                  {this.state.sessionDateerror ? (
                      <Alertlogmsg
                        msgtext={this.state.sessionSelectionMsg}
                        boxclass="task-running"
                      />
                    ) : null}

                    {this.state.showOverQuota? (
                       <Alertlogmsg
                       msgtext={this.state.showOverQuotaMsg}
                       boxclass="task-running"
                     />
                    ) : null }

                    {this.state.showApplyResposeSuccess? (
                       <Alertlogmsg
                       msgtext={this.state.showApplyResposeMsgSuccess}
                       boxclass="task-running"
                     />
                    ) : null }
                    {this.state.showApplyResposeError? (
                       <Alertlogmsg
                       msgtext={this.state.showApplyResposeMsgError}
                       boxclass="task-running"
                     />
                    ) : null }
                  <div className="leave_content_body">
                   <Row>
                     <Col xs={12} sm={6} md={6}>
                     <FormGroup id="leave-type">
                        <Label for="exampleSelect">Leave Type</Label>
                        <Input type="select" name="select" id="typeofleaves" onChange={this.getLeaveTypeBalance.bind(this)} className={this.state.leaveTypeError? 'field-error' : ''}>
                        <option value=''>Select</option>
                          {this.state.leaveTypes.map((type, index) =>
                          {
                            return(
                              <option key={index}  value={type.id}>{type.leave_type == 'General Leave'? 'Personal Leave': type.leave_type}</option>
                            )
                          })}                       
                        </Input>
                      </FormGroup>
                     </Col>
                     <Col xs={12} sm={6} md={6}>
                     <FormGroup id="session-1" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                        <Label for="exampleSelect">Sessions</Label>
                        <Input type="select" name="select" id="sessions" onChange={this.onSessionChange.bind(this)}>
                        {this.state.sellectedType != 5 && this.state.sellectedType !=7 ?
                        (
                          <Fragment>
                              <option value="1">First Half</option>                       
                              <option value="2">Second Half</option>
                          </Fragment>
                        )
                        : null
                        }                        
                          <option value="3" selected >Full Day</option>
                        </Input>
                      </FormGroup>
                     </Col>
                     {this.state.sellectedType == 5 ?
                      (                      
                          <FormGroup id="session-1" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                            <Label for="exampleSelect">Occasion</Label>
                            <Input type="select" name="select" id="holidays" onChange={this.onHolidaySelect.bind(this)}>
                              <option value="">Select</option>
                              {this.state.holidays.map((holiday, index)=>
                              {
                                return(
                                  <option key={index} value={holiday.id}>{holiday.holiday_date +" - "+holiday.occasion}</option>
                                )
                              })}
                            </Input>
                          </FormGroup>                     
                      ):
                      (
                      <Fragment>
                     <Col xs={12} sm={6} md={6}>
                     <FormGroup id="start-date" className={this.state.sellectedType ==='' && 'leave-hidden-field'}>
                            <Label>From Date</Label>
                            <DateTimePicker
                            maxDate={this.state.maxDate}
                            calendarIcon={
                            <img
                            className="calender-icon"
                            src={CalenderIcon}
                            width="12"
                            height="12"
                            alt="CalenderIcon"
                            />
                            }
                            value={this.state.startDate}
                            onChange={this.onSdateChange}
                            format="y-MM-dd"
                            />
                          </FormGroup>
                     </Col>
                     <Col xs={12} sm={6} md={6}>
                     <FormGroup id="to-date" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                            <Label>To Date</Label>
                            <DateTimePicker
                            maxDate={this.state.maxDate}
                            calendarIcon={
                            <img
                            className="calender-icon"
                            src={CalenderIcon}
                            width="12"
                            height="12"
                            alt="CalenderIcon"
                            />
                            }
                            value={this.state.endDate}
                            onChange={this.onEdateChange}
                            format="y-MM-dd"
                            />
                          </FormGroup>
                     </Col>
                     </Fragment>
                       )
                      }
                      <Col xs={12} sm={6} md={6}>
                      <div className={`leave-details ${this.state.sellectedType === '' && ' leave-hidden-field'}`}>
                        <Label>Leave Details</Label>
                        <div className={"leave-details-wrapper d-flex " + (this.state.applyingOverQuota? 'over-limit-leave':'') } >
                          <div className="leave-app-for d-flex">
                            <p>Applying for: {this.state.applyingFor>0? "-"+this.state.applyingFor: this.state.applyingFor }</p>
                          </div>
                          <div className="leave-bal d-flex">
                            {this.state.sellectedType!= 0 && this.state.sellectedType !=9 ?
                            ( <p>Available: {this.state.leaveBalance}</p>)
                            :
                            null
                            }
                           
                          </div>
                        </div>
                      </div>
                      </Col>
                      <Col xs={12} sm={6} md={6}>
                      <FormGroup id="contact-details" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                        <Label for="exampleEmail">Contact Details</Label>
                        <Input
                          className={this.state.leavePhoneError? 'field-error' : ''}
                          type="text"
                          name="email"
                          id="exampleEmail"
                          placeholder="Mobile Number"
                          value={this.state.myPhoneNumber}
                          onChange={this.onNumberChange.bind(this)}
                        />
                      </FormGroup>
                      </Col>
                      <Col xs={12} sm={6} md={{offset: 3,size: 6}}>
                        <FormGroup id="attachment" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                          <p>Attachments</p>
                          {/* <div className="file-wrapper">
                            <Input
                              type="file"
                              name="file"
                              id="leave-attachment"
                            ></Input>
                            <Label
                              htmlFor="leave-attachment"
                              for="exampleSelect"
                            >
                              <p>Click here to Upload</p>
                              <img src={AttachmentIcon} />
                            </Label>
                          </div> */}
                          <FileUpload 
                          myid={this.state.myId} 
                          leavefiles={this.state.storeFileNames}
                          storefiles={this.storeFileNames} />
                           {(this.state.leaveFiles && this.state.leaveFiles.length>0 && this.state.leaveFilesChange) &&
                            (
                              <FileViewer 
                              attachments={this.state.leaveFiles} 
                              deleteitem="yes"
                              deletefiles={this.deletefiles} 
                              arrayname={'leaves'}
                              listtype={1}
                              taskAttachmenttextarray={[]}
                              updatefiletext={''}
                              />
                            )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={6} md={6}>
                      <FormGroup id="apply-to" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                        <Label for="exampleSelect">To</Label>
                        <Input type="select" name="select" id="reporters" onChange={this.onReporterSelect.bind(this)}
                        className={this.state.leaveToError? 'field-error' : ''}
                        >
                          <option>Select</option>
                          {this.state.allReporters.map((reporter, index) =>
                            {
                              return(
                                <option key={index} value={reporter.id}>{reporter.name}</option>
                              )
                            })}
                        </Input>
                      </FormGroup>
                      </Col>
                      <Col xs={12} sm={6} md={6}>
                      <FormGroup id="leave-cc" className={this.state.sellectedType === '' && 'leave-hidden-field'}>
                        <Label for="exampleSelect">CC</Label>
                        <div id="cc-people" className="d-flex">
                          <div className="people">
                            <p className={"d-flex cc-person-wrapper "+(this.state.leaveCCError? 'field-error' : '')}>
                              
                                {this.state.allUsers.map((people, index) => {
                                    return(
                                      <span className="cc-person" key={index}>
                                        {array.indexOf(people.id) !== -1? people.name+"," : null}
                                      </span>
                                    )
                                })}
                              
                              {/* <span className="add-cc-people">
                                <img
                                  src={closeIconImg}
                                  width="8px"
                                  height="8px"
                                  alt="close-icon"
                                />
                              </span> */}
                            </p>
                            <Button className="btn-green btn-add-cc-person" onClick={this.showCcPeople.bind(this)}>
                            <PlusIcon />
                          </Button>
                          </div>
                          
                          <div className={this.state.showCcPeople? "cc-all-peoples show-all": "cc-all-peoples"} onMouseLeave={this.hideCcPeople.bind(this)}>
                            {/* <Input type="text" value={this.state.nameFilter} onChange={this.onNameFilterChange.bind(this)} /> */}
                            <CcPersonSingle peoples={this.state.allUsers} selectedpeoples={this.state.selectedPeoples} onccchange={this.onCcSet}  />
                            {/* {this.state.allUsers.map((people, index)=>
                              {
                                  return(
                                    <CcPersonSingle peopleid={people.id} peoplename={people.name} peopleemail={people.email} selectedpeoples={this.state.selectedPeoples} onccchange={this.onCcSet}  />
                                  )
                              })}                                                    */}
                          </div>
                        </div>
                      </FormGroup>
                      </Col>
                      <Col xs={12} sm={6} md={12}>
                      <div className={`text-area ${this.state.sellectedType === '' && 'leave-hidden-field'} `}>
                        <Label for="exampleText">Reason</Label>
                        <Input
                          className={"leave-reason-textarea "+(this.state.leaveReasonError? 'field-error' : '')}
                          type="textarea"
                          name="text"
                          id="exampleText"
                          placeholder="Enter the reason for applying leave. (Maximum 100 characters)"
                          value={this.state.myLeaveReason}
                          onChange={this.onReasonChange.bind(this)}
                          maxLength={100}
                        />
                      </div>
                      </Col>
                      <Col xs={12} sm={12} md={12}>
                      <div  className={`leave_submit-btns ${this.state.sellectedType === '' && 'leave-hidden-field'} `}>
                        <Button className="btn-green btn-leave-submit" onClick={this.applyForLeave.bind(this)}>
                        Apply Leave
                        </Button>
                        {/* <Button className="btn-gray btn-leave-submit">
                          Cancel
                        </Button> */}
                      </div>
                      </Col>
                   </Row>
                  </div>
                </div>
            </Card>
            </Col>
            <Col sm={12} lg={4}>
            <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">Calendar</CardTitle>
                <LeaveCalenderDetails/>
            </Card>
          </Col>
          </Row>
           
        </div>
      </Row> 
      </Container>
      
      
      
      
      
      {/* <section id="leave_apply"></section> */}
      
        
      </div>
    );
  }
}
