import React from 'react';

const getFileType = (fileUrl) => {
  const extension = fileUrl
    .split('.')
    .pop()
    ?.toLowerCase()
    .trim();
  const videoExtensions = [
    'mp4',
    'mov',
    'avi',
    'wmv',
    'flv',
    'webm',
    'mkv',
    'm4v',
    '3gp',
    'mpeg'
  ];

  const zipExtensions = [
    'zip',
    'rar',
    '7z',
    'tar',
    'gz',
    'bz2'
  ];

  if (videoExtensions.includes(extension)) {
    return 'video_file';
  }

  if (zipExtensions.includes(extension)) {
    return 'folder_zip';
  }
  return 'attachment';
};

export default getFileType;