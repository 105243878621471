import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import {urls} from "../../services/UrlList";
import {Container, Row, Col,  Button,  Breadcrumb, BreadcrumbItem} from 'reactstrap';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import BrandNote from './BrandNote';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import MaterialIcon from "material-icons-react";
import ProjectCurrentStatus from './ProjectCurrentStatus';
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from './MailboxEmail';


const notifySuccess = () => toast('Brand note created successfully.');
const notifyError = () => toast('Something went wrong. Please try again.');
const notifyEditSuccess = () => toast('Brand note updated successfully.');
const notifyDeleteSuccess = () => toast('Brand note successfully deleted');

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link', 'image'],
  ['clean']    
];

export default class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveupdateProgress:false,
      display: false,
      brandNote:'',
      brandName:'',
      noteEditId:0,
      editIndex:'',
      allFolders:[],
      myId:'',
      brandNotes:[],
      detailError:false,
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      showMenu:true,
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.reactTags = React.createRef();
    this.editorRef = React.createRef() 
  }
//faheem code----------------------------

toggleDisplay() {
  this.setState({
    display: !this.state.display
  });
}
handleChangeEditor(value) {
  this.setState({ brandNote: value })
}


//-----------------------------------------
  // isBottom(el){
  //   return el.getBoundingClientRect().bottom <= window.innerHeight;
  // }

  componentDidMount () {
    document.title = "KIMPHUB - Brands";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    this.getAllFolders();
    this.getAllNotes();
  };


  componentWillUnmount() {  
    this.checkUserPrivilege();
    this.getAllFolders();
    this.getAllNotes();
  }
 

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };


  // renderContentDevice = () => {
  //   if (isMobile && !this.state.myPrivileges.includes('am_admin')) {
  //       this.setState({isMobileView:1});
  //   } 
  //   if (isMobile) {
  //     this.setState({showMenu:false});
  //   }  
  //};

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  handleInputChange(input, e) {
    this.setState({ selectedClient :e});
  }

 
  convertTimeFormat = (time) =>
	{
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}
  handleInputChange(input, e) {
    console.log("value", input)
  }

  showFolderAssets = (id, type) =>
  {
    if(type === 0)
      this.props.history.push("/brand/assets/"+this.props.match.params.bid+"/"+this.props.match.params.id+"/"+id+"?uuid="+this.state.myId);
    else
    this.props.history.push("/brand/preset/"+this.props.match.params.bid+"/"+this.props.match.params.id+"/"+id+"?uuid="+this.state.myId);
  }


  getAllFolders = () =>{  
    let opts ={
      project_id: this.props.match.params.bid,
      brand_id:this.props.match.params.id 
    }      
    fetch(urls.getProjectBrandFolders,{
			headers:{
				'Access-Control-Allow-origin':'*',
				'Content-Type':'application/json'
			},
			method: "post",
        	body: JSON.stringify(opts)
		})
    .then(response => response.json())
    .then(response => {     
        this.setState({
          accessDenied: response.valid === 10 ? true : false,
          allFolders: response.valid !== 10 && response.folders, 
          brandName: response.valid !== 10 && response.brand_name,
          projectDetails: response.valid !== 10 && response.project_details[0],
          progressBar:false
        });            
    })
    .catch(error=>
      {
        this.setState({
          accessDenied: true,
          allFolders: [], 
          brandName: '',
          projectDetails: [],
          progressBar:false
        });  
      })
  };

  createBrandNote = () =>
  {
      this.setState({detailError:false});
      if(this.state.brandNotes === '')
      {
          this.setState({detailError:true});
          return false;
      }
      this.setState({saveupdateProgress:true});
      const data = {
          project_id:this.props.match.params.bid,
          brand_id: this.props.match.params.id,
          notes: this.state.brandNote,
          user_id: this.state.myId,
          board_name: this.state.projectDetails.board_name,
          brand_name:this.state.brandName
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin':'*',
        }
        axios.post(urls.postBrandNote, data, {
            headers: headers
          })
          .then((response) => {
            this.setState({
              saveupdateProgress:false,
              brandNotes:response.data.notes,
              brandNote:''
            })
            notifySuccess();
          })
          .catch((error) => {
              this.setState({saveupdateProgress:false});
              notifyError();
          })
  }

  deleteBrandNote = (indexkey, id) =>
  {
      this.setState({detailError:false});
      if(this.state.brandNotes === '')
      {
          this.setState({detailError:true});
          return false;
      }
      const data = {
          project_id:this.props.match.params.bid,
          brand_id: this.props.match.params.id,
          note_id: id,
          user_id: this.state.myId
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin':'*',
        }
        axios.post(urls.deleteBrandNote, data, {
            headers: headers
          })
          .then((response) => {
            if(response.data.valid === 1)
            {
              this.reorderList(indexkey);
              notifyDeleteSuccess();
            }else
            {
              notifyError();
            }
          })
          .catch((error) => {
              notifyError();
          })
  }

  reorderList(lkey)
  {
    var templist = [...this.state.brandNotes];
    templist.splice(lkey, 1);
    this.setState({brandNotes:templist});
  }

  async updateList(lkey)
  {
    var templist = [...this.state.brandNotes];
    templist[lkey].notes = this.state.brandNote;
    this.setState({brandNotes:templist});
    this.setState({
      brandNote:'',
      noteEditId:0,
      editIndex:''
    })
  }


  getAllNotes = () =>{        
    const data = {
      project_id:this.props.match.params.bid,
      brand_id: this.props.match.params.id,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getBandNotes, data, {
        headers: headers
      })
      .then((response) => {
        console.log(response);
            this.setState({
              brandNotes:response.data.notes
            })
      })
      .catch((error) => {
          console.log("hi");
      })
  };

  enableNoteEdit = (id, note, noteindex) =>
  {
    this.setState({
      brandNote:note,
      noteEditId:id,
      editIndex:noteindex
    });
    this.editorRef.current.scrollIntoView();
  }

  cancelEditing =()=>
  {
    this.setState({noteEditId:'',brandNote:''});
  }

  editBrandNote = () =>
  {
    this.setState({detailError:false});
    if(this.state.brandNotes === '')
    {
        this.setState({detailError:true});
        return false;
    }
    this.setState({saveupdateProgress:true});
    const data = {
        project_id:this.props.match.params.bid,
        brand_id: this.props.match.params.id,
        note_id: this.state.noteEditId,
        note: this.state.brandNote,
        user_id: this.state.myId,
        board_name: this.state.projectDetails.board_name,
        brand_name:this.state.brandName
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.editBrandNote, data, {
          headers: headers
        })
        .then((response) => {
          if(response.data.valid === 1)
          {
            this.updateList(this.state.editIndex);
            notifyEditSuccess();
          }else
          {
            notifyError();
          }
          this.setState({saveupdateProgress:false});
        })
        .catch((error) => {
            this.setState({saveupdateProgress:false});
            notifyError();
        })
  }

  goToHome = () =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }

  goToProjectsHome = () =>
  {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  stripslashes = (str) => {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
 }

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container className="top-bar-inactive bc_module_container">

      <Row>
        <div className="theme_header">
           <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
             <div className="SideNavbar">
            <SideMenu  
            activeitem="project" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
            </div>
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
       <LoadingWrapper isLoading={this.state.progressBar}>
          {this.state.accessDenied ?
          (
            <>
              <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
            </>
          ):
          (
          <>
              <Row>
                <div className="bc_module">
                <Col sm="12">
                <Toaster position="top-right" containerClassName="notifier"
                      toastOptions={{
                        // Define default options
                        className: '',
                        duration: 5000,
                        style: {
                          background: '#363636',
                          color: '#fff',
                        },}}
                  />
                <div className="bc_Board_wrapper">
                  <h2 class="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                  <ProjectCurrentStatus 
                  graphics={this.state.projectDetails.graphics_pack}
                  video={this.state.projectDetails.video_pack}
                  graphics_video={this.state.projectDetails.graphics_video_pack} 
                  canva_graphics={this.state.projectDetails.canva_graphics}
                  canva_plus={this.state.projectDetails.canva_plus}
                  whitelabel={this.state.projectDetails.white_label}
                  clientstatus={this.state.projectDetails.client_status}/>
                  
                  </h2>
                  <p class="board_discrption">{this.state.projectDetails.short_description}</p>
                  <MailboxEmail canva_access={this.state.projectDetails.canva_access} />
                  <h2 className="section_title subtitle">{this.state.brandName}</h2>
                  <div className="page_header_wrap">
                     <Row>
                       <Col sm={6}></Col>
                       <Col sm={6}>
                      <div className="search-box"> 
                          <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards')} > <MaterialIcon icon="fact_check"  /> </a>
                          <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                      </div>
                      </Col>
                      </Row>
                  </div>
                </div>
                  <div className="breadcrumb_boards">
                  <Breadcrumb>
                      <BreadcrumbItem><a  href={"/boards"+"?uuid="+this.state.myId} >Boards</a></BreadcrumbItem>
                      <BreadcrumbItem><a  href={'/boardsdetails/'+this.props.match.params.bid+"?uuid="+this.state.myId} >Board Details</a></BreadcrumbItem>
                      <BreadcrumbItem><a  href={'/docandfiles/'+this.props.match.params.bid+"?uuid="+this.state.myId} >Doc and Files</a></BreadcrumbItem>
                      <BreadcrumbItem active>{this.state.brandName}</BreadcrumbItem>
                    </Breadcrumb>
                    </div>
              </Col>
              
              
              <Col sm="12">
              <div className="brands_wrapper">
                {this.state.allFolders.map((folder, index)=>
                {
                  return(
                    <div key={folder.id} className="folder_box card" onClick={this.showFolderAssets.bind(this.state, folder.id, folder.special_folder)}>
                      <MaterialIcon icon={folder.special_folder ===1 ? "text_rotation_none": "folder"} />
                      <span className="folder_name">{folder.name}</span>
                      <span className='d-flex justify-content-between brand-asset-counts' >
                        {(folder.asset_count>0 || folder.sub_folder_count>0) ?
                        (
                        <>
                          <span className="asset_count">{folder.asset_count>0 ? `Files: ${folder.asset_count}` : ''}</span>
                          <span className="subfolfer_count">{folder.sub_folder_count>0 ? `Folders: ${folder.sub_folder_count}` : ''}</span>
                        </>
                        ):
                        (
                          <span className='d-flex justify-content-center' style={{width:'100%'}}>{'No Assets'}</span>
                        )}
                      </span>
                    </div>
                  )
                })}
              </div>
              </Col>
              <Col sm="12">
                <div className="note_add_wrapper" ref={this.editorRef}>
                        {AccessCheckBlock('add_edit_delete_pin_notes') &&
                        (
                          <>
                              <h2 className="barnd_not_titile">Notes</h2>
                              <ReactQuill value={this.stripslashes(this.state.brandNote)}
                              onChange={this.handleChangeEditor}
                              className={this.state.detailError? 'field-error edit_wrp' : ''}
                              placeholder= "Add Notes Here....."
                              modules={{toolbar: toolbarOptions}} 
                              theme="snow" />
                              <div className="ad_btn_wrap">
                                {this.state.noteEditId>0?
                                (
                                  <>
                                    <Button  type="submit" className="btn-grey"
                                    onClick={this.cancelEditing} >Cancel</Button>

                                    <Button  type="submit" className="add_cmd_btn_note"
                                    disabled={this.state.saveupdateProgress && 'disabled'} 
                                    onClick={this.editBrandNote} >Update Comment</Button>
                                  </>
                                )
                                :
                                (
                                  <Button type="submit" className="add_cmd_btn_note"
                                  disabled={this.state.saveupdateProgress && 'disabled'}  
                                  onClick={this.createBrandNote}  >Add Comment</Button>
                                )}
                              </div>
                          </>
                        )
                        }
                        {this.state.brandNotes.length>0 &&
                        (
                          <div className="comment_preview_wrap">
                            <h3>Comments</h3>
                            {this.state.brandNotes.map((bnote, index)=>
                            {
                              return(
                                <BrandNote indexkey={index} key={bnote.note_id} brandnote={bnote} 
                                mytimezone={this.state.myTimezone}
                                deletenote={this.deleteBrandNote} 
                                editnote={this.enableNoteEdit} /> 
                              )
                            })}
                          </div>
                        )}
                  </div>
              </Col>
              </div>
            </Row>
            </>
          )}
        </LoadingWrapper>
       </div>

      </Row>





        
      </Container>
    </div>
    )
  }
}
