import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import './maintenance.css';

export default class Maintenance extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      showProgressSearch: true,
      orginalEntries:[],
      fullEntries:[],
      orginalUsers:[],
      selectedUser:'',
      myPrivileges:[],
      showMenu:true,     
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Switch User";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
     // _this.getlogTimerReport(); 
    }, 100);
    
  //  this.reloadChecker(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        //localStorage.clear();
        this.clearLocalStorage();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              //localStorage.clear();
              this.clearLocalStorage();
              this.props.history.push("/");
            })
            .catch(error => {
              //localStorage.clear();
              this.clearLocalStorage();
              this.props.history.push("/");
            });
       
      });
  };

  clearLocalStorage = ()=>
    {
      localStorage.removeItem('khubexpire'); 
      localStorage.removeItem('user'); 
      localStorage.removeItem('firstlogin'); 
      localStorage.removeItem('superadmin');
    }

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("superadmin") === null)
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

 
  render() {

    
    return (
      <Container fluid>
        <Fragment>
          <Row>
          <Col sm="1" xl="2">
              <SideMenu activeitem="switch" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
          </Col>
          <Col sm="10">
              <Header {...this.props}  classname="tick-timer-header"  logout={this.signOut.bind(this)} onmenuaction={this.toggleSidebar.bind(this)} />
              <Container>
                <Row>
                <Col sm="12">
                    <div className="maintenance-msg d-flex justify-content-center align-items-center">
                      <p>You don't have access to view that page or the module is currently undergoing maintenance.</p>
                    </div>
                 </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Fragment>
      </Container>
    )
  }
}