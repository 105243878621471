import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../../services/UrlList";
import { Typeahead } from "react-bootstrap-typeahead";

class UserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

   getAllUsers = () =>{  
    let opts ={    
      team_id: this.props.teamid,
      user_id: this.props.myid,
      basecamp_id:this.props.baseid,
      user_type: 1
    } 
    var fUrl = urls.getLoanPeople;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {        
        this.setState({
          orginalUsers: response,          
        });       
      } 
    })
  };


  onChangePeople = (e) => {
    this.setState({ selectedUser:e.target.value});
    setTimeout(() => {
      this.props.peoplechange(this.state.selectedUser);
    }, 100);     
  };


  handleInputChange = (input, e) => {
    console.log("value", input);
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if (size > 0) {
      this.setState({ selectedUser:selectedOptions[0].id});
      this.props.peoplechange(selectedOptions[0].id);
    }else
    {
      //this.setState({ selectedUser:''});
      this.props.peoplechange('');
    }
  };
  

    render() {
      return (
        <div className="people date-col">
          {/* <select className="form-control" onChange={this.onChangePeople.bind(this)}>
          <option value="">All People</option>
          {this.state.orginalUsers.map((user, index) =>
          {
            return(
              <option value={user.id}>{user.name}</option>
            )
          })}
          </select> */}
          <Typeahead
              id="user-picker"
              labelKey="name"
              onInputChange={this.handleInputChange}
              onChange={this.handleChange}
              options={this.state.orginalUsers}
              placeholder="Choose member"
              className="head-filter-member-fa"
              selected=""
            />
        </div>
      );
    }
}

export default UserPicker;
