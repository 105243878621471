import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../login/Login";
import LoginPortal from "../loginportal/LoginPortal"
// import AccessDenied from "../components/AccessDenied";
// import UserEntries from "../components/Tick/Entries";

const NotFound = () => (
  <div
    style={{
      marginTop: "15%"
    }}
  >
    <h1
      style={{
        textAlign: "center"
      }}
    >
      404
    </h1>
    <h1
      style={{
        textAlign: "center"
      }}
    >
      Page Not Found
    </h1>
  </div>
);
const Routes = props => (
  <main>
    <Switch>
      <Route exact path="/" component={Login} />  
      <Route exact path="/login-portal" component={LoginPortal} />  
      {/* <Route exact path="/" component={UserEntries} /> */}
     
      {/* <Route exact path="/accessdenied" component={AccessDenied} />
      <Route exact path="/tick/entries" component={UserEntries} />
      <Route path="*" component={NotFound} /> */}
    </Switch>
  </main>
);

export default Routes;
